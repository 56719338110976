import pdfMake from 'pdfmake/build/pdfmake';
import docDefinition from './docDefinition';
import tableLayouts from './tableLayouts';
import { BaseData } from '../../../lib';
import getCruises from './getCruises';
import { trackGA4Download } from '../../../tealium';

/**
 * TUICUNIT-1737
 * http://pdfmake.org/
 * */
export default async function createPdf(baseData: BaseData, callback: () => void) {
  const cruises = await getCruises(baseData);
  pdfMake.createPdf(docDefinition(cruises), tableLayouts).download('TUIC-PLUS-und-PUR-Tarif-Tabelle.pdf', () => {
    trackGA4Download('TUIC-PLUS-und-PUR-Tarif-Tabelle.pdf');
    callback();
  });
};

import * as React from 'react';
import styled from 'styled-components/macro';

import { colors } from '../lib';
import Fixed from './Fixed';

const ColumnsContext = React.createContext<{
  columns: number;
  active?: boolean;
} | null>(null);

const Table = styled.div`
  margin-bottom: 80px;
  padding-top: 136px;
  width: 100%;
`;

export const Title = styled.span`
  font-size: 22px;
  min-height: 28px;
  margin-bottom: 6px;
  display: block;
  font-weight: bold;
`;
export const TitleSecondTd = styled.span`
  display: block;
  margin-left: 144px;
`;

export const Tr = styled.span`
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-end;
`;

type TdProps = {
  right?: boolean;
  active?: boolean;
  topBorder?: false;
  triangle?: boolean;
  columns: number;
  filtered?: boolean;
};
const StyledTd = styled.span`
  display: block;
  position: relative;
  padding: 20px 16px;
  width: 5%;
  flex: 0 0 30%;
  color: ${({filtered}: TdProps) =>
    filtered || filtered === undefined ? 'unset' : colors.disabledGray
  };
  max-width: ${({ columns }: TdProps) =>
    Math.max(Math.min(750 / columns, 220), 167)}px;
  min-width: 148px;
  border-left: 1px solid
    ${({ active }: TdProps) =>
      active ? colors.lightGrayBlue : colors.disabledGray};
  border-top: 1px solid
    ${({ topBorder, active }: TdProps) =>
      topBorder === false
        ? 'transparent'
        : active
        ? colors.lightGrayBlue
        : colors.disabledGray};
  text-align: ${({ right }: TdProps) => (right ? 'right' : 'left')};
  &:first-child {
    flex: 1 0 auto;
    max-width: 100%;
    border-left: none;
  }
  &::before {
    position: absolute;
    display: ${({ triangle }: TdProps) => (triangle ? 'block' : 'none')};
    content: '';
    top: 0;
    right: 0;
    width: 0;
    height: 0;
    border-top: 16px solid ${colors.blue};
    border-left: 16px solid transparent;
  }
`;

export const Td: React.FC<Omit<TdProps, 'columns'>> = (props) => {
  const context = React.useContext(ColumnsContext);
  if (context === null) {
    throw new Error(`Can not render Td outside of Table`);
  }

  return <StyledTd {...props} {...context} />;
};

type TableProps = {
  head: React.ReactElement;
  columns: number;
  active?: boolean;
  headerTopOffset: number;
};

type FixedHeaderProps = {
  top: number;
  height: number;
};
const FixedWithOffset = styled(Fixed)`
  background: white;
  box-shadow: 0 9px 17px 0 rgba(0, 0, 0, 0.03);
  height: ${({ height }: FixedHeaderProps) => height}px;
  left: 0;
  top: ${({ top }: FixedHeaderProps) => top}px;
`;

function useSticky<E extends HTMLElement>(
  offset: number,
): [React.MutableRefObject<E | null>, boolean, number] {
  const [sticky, setSticky] = React.useState(false);
  const [height, setHeight] = React.useState(0);
  const ref = React.useRef<E | null>(null);
  React.useEffect(() => {
    const listener = () => {
      window.requestAnimationFrame(() => {
        if (!ref.current) {
          return;
        }

        const { top, height: newHeight } = ref.current.getBoundingClientRect();
        if (newHeight !== height) {
          setHeight(newHeight);
        }
        if (top <= offset && !sticky) {
          setSticky(true);
        } else if (top > offset && sticky) {
          setSticky(false);
        }
      });
    };
    listener();
    window.addEventListener('scroll', listener);

    return () => {
      window.removeEventListener('scroll', listener);
    };
  }, [offset, sticky, setSticky, height, setHeight]);

  return [ref, sticky, height];
}

type HeadProps = {
  active?: boolean;
};
const Head = styled.span`
  color: ${({ active }: HeadProps) =>
    active ? colors.darkBlue : colors.disabledGray};
`;

const TableWithFloatingHeader: React.FC<TableProps> = ({
  children,
  head,
  active,
  headerTopOffset,
  columns,
}) => {
  const [ref, sticky, height] = useSticky<HTMLDivElement>(headerTopOffset);
  const context = React.useMemo(() => ({ active, columns }), [columns, active]);

  return (
    <ColumnsContext.Provider value={context}>
      <Table>
        <Head ref={ref} active={active}>
          {head}
        </Head>
        {children}
      </Table>
      <FixedWithOffset top={headerTopOffset} height={sticky ? height : 0}>
        {sticky ? (
          <Head active={active} data-testid="sticky-tablehead">
            {head}
          </Head>
        ) : null}
      </FixedWithOffset>
    </ColumnsContext.Provider>
  );
};

export default TableWithFloatingHeader;

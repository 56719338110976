export default function inRange(
  value: number,
  [min, max]: [number, number],
): number {
  switch (true) {
    case value < min:
      return min;
    case value > max:
      return max;
    default:
      return value;
  }
}

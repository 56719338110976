import CruiseList from './CruiseListProvider';
import { withUserAndBaseData } from '../../lib';
import {
  Cruise as CruiseType,
  Cabin as CabinType,
  Price as PriceType,
  CabinAndFlightPrice as CabinAndFlightPriceType,
  isCabinWithFlight,
  isCabinWithFlightOnly,
  isCabinWithoutFlight,
} from './Types';

export default withUserAndBaseData(CruiseList);
export { default as sortByAmount } from './sortByAmount';
export { default as convertCruises } from './convertCruises';
export type Cruise = CruiseType;
export type Cabin = CabinType;
export type Price = PriceType;
export type CabinAndFlightPrice = CabinAndFlightPriceType;
export { isCabinWithFlight, isCabinWithFlightOnly, isCabinWithoutFlight };

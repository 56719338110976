import { Option } from '../../types';
import { formatISO } from '../../lib/formatDate';

export type SearchState = {
  adults: number | '';
  children: number | '';
  cruiseDuration: Option;
  firstCheckInDate: Date;
  arrivalAirports: Option[];
  departureAirports: Option[];
  lastCheckOutDate: Date;
  ships: Option[];
  regions: Option[];
  cabinTypes: Option[];
  arrivalLikeDeparture: boolean;
};

function serializeValue(
  value: SearchState[keyof Omit<SearchState, 'arrivalLikeDeparture'>],
) {
  if (Array.isArray(value)) {
    return value.map(({ code }) => code).join(',');
  }
  if (value instanceof Date) {
    return formatISO(value);
  }
  if (value && typeof value === 'object') {
    return value.code;
  }

  return `${value}`;
}

export function serialize(state: SearchState): string {
  const { arrivalLikeDeparture, ...rest } = state;

  return Object.entries(rest)
    .map(
      ([key, value]) =>
        `${key}=${serializeValue(
          arrivalLikeDeparture && key === 'arrivalAirports'
            ? rest.departureAirports
            : value,
        )}`,
    )
    .join('&');
}

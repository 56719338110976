/**
 * DO NOT MODIFY - This file has been generated using oazapfts.
 */
type Encoders = Array<(s: string) => string>;
// Encode param names and values as URIComponent
const encodeReserved = [encodeURIComponent, encodeURIComponent];
// Allow reserved chars in param values
const allowReserved = [encodeURIComponent, encodeURI];
type RequestOpts = {
    headers?: Record<string, string | undefined>;
    method?: string;
};
type FetchRequestOpts = RequestOpts & {
    body?: string | FormData;
};
type JsonRequestOpts = RequestOpts & {
    body: object;
};
type MultipartRequestOpts = RequestOpts & {
    body: Record<string, string | Blob | undefined | any>;
};
type ServerI = {
    url: string;
};
export type ApiOptions = {
    /**
     * @deprecated "baseUrl" has been renamed to "server"
     */
    baseUrl?: string;
    server?: ServerI | string;
    fetch?: typeof fetch;
} & RequestInit;
function getUrl(server: string | ServerI): string {
    if (typeof server === "string") {
        return server;
    }
    return server.url;
}
export class Api {
    private _baseUrl: string;
    private _fetchImpl?: typeof fetch;
    private _fetchOpts: RequestInit;
    constructor({ baseUrl = "https://api.meinschiff.com/cruisesearch/{version}/", server, fetch: fetchImpl, ...fetchOpts }: ApiOptions = {}) {
        this._fetchImpl = fetchImpl;
        this._baseUrl = server ? getUrl(server) : baseUrl;
        this._fetchOpts = fetchOpts;
    }
    private async _fetch(url: string, req: FetchRequestOpts = {}) {
        const headers = stripUndefined(req.headers);
        const res = await (this._fetchImpl || fetch)(this._baseUrl + url, {
            ...this._fetchOpts,
            ...req,
            headers
        });
        if (!res.ok) {
            throw new HttpError(res.status, res.statusText);
        }
        return res.text();
    }
    private async _fetchJson(url: string, req: FetchRequestOpts = {}) {
        const res = await this._fetch(url, {
            ...req,
            headers: {
                ...req.headers,
                Accept: "application/json"
            }
        });
        return JSON.parse(res);
    }
    private _json({ body, headers, ...req }: JsonRequestOpts) {
        return {
            ...req,
            body: JSON.stringify(body),
            headers: {
                ...headers,
                "Content-Type": "application/json"
            }
        };
    }
    private _form({ body, headers, ...req }: JsonRequestOpts) {
        return {
            ...req,
            body: QS.form(body),
            headers: {
                ...headers,
                "Content-Type": "application/x-www-form-urlencoded"
            }
        };
    }
    private _multipart({ body, ...req }: MultipartRequestOpts) {
        const data = new FormData();
        Object.entries(body).forEach(([name, value]) => {
            data.append(name, value);
        });
        return {
            ...req,
            body: data
        };
    }
    /**
     * retrieves base data
     */
    async getBaseData() {
        return await this._fetchJson("/baseData") as baseDataResponse;
    }
    /**
     * searches cruise offers
     */
    async postCruiseList(cruiseListRequest: cruiseListRequest) {
        return await this._fetchJson("/cruiseList", this._json({
            method: "POST",
            body: cruiseListRequest
        })) as cruiseListResponse;
    }
}
/**
 * Deeply remove all properties with undefined values.
 */
function stripUndefined<T>(obj: T) {
    return obj && JSON.parse(JSON.stringify(obj));
}
/**
 * Creates a tag-function to encode template strings with the given encoders.
 */
function encode(encoders: Encoders, delimiter = ",") {
    const q = (v: any, i: number) => {
        const encoder = encoders[i % encoders.length];
        if (typeof v === "object") {
            if (Array.isArray(v)) {
                return v.map(encoder).join(delimiter);
            }
            const flat = Object.entries(v).reduce((flat, entry) => [...flat, ...entry], [] as any);
            return flat.map(encoder).join(delimiter);
        }
        return encoder(String(v));
    };
    return (strings: TemplateStringsArray, ...values: any[]) => {
        return strings.reduce((prev, s, i) => {
            return `${prev}${s}${q(values[i] || "", i)}`;
        }, "");
    };
}
/**
 * Separate array values by the given delimiter.
 */
function delimited(delimiter = ",") {
    return (params: Record<string, any>, encoders = encodeReserved) => Object.entries(params)
        .filter(([, value]) => value !== undefined)
        .map(([name, value]) => encode(encoders, delimiter) `${name}=${value}`)
        .join("&");
}
/**
 * Functions to serialize query parameters in different styles.
 */
export const QS = {
    encode,
    encodeReserved,
    allowReserved,
    /**
     * Join params using an ampersand and prepends a questionmark if not empty.
     */
    query(...params: string[]) {
        const s = params.join("&");
        return s && `?${s}`;
    },
    /**
     * Serializes nested objects according to the `deepObject` style specified in
     * https://github.com/OAI/OpenAPI-Specification/blob/master/versions/3.0.0.md#style-values
     */
    deep(params: Record<string, any>, [k, v] = encodeReserved): string {
        const qk = encode([s => s, k]);
        const qv = encode([s => s, v]);
        // don't add index to arrays
        // https://github.com/expressjs/body-parser/issues/289
        const visit = (obj: any, prefix = ""): string => Object.entries(obj)
            .filter(([, v]) => v !== undefined)
            .map(([prop, v]) => {
            const index = Array.isArray(obj) ? "" : prop;
            const key = prefix
                ? qk `${prefix}[${index}]` : prop;
            if (typeof v === "object") {
                return visit(v, key);
            }
            return qv `${key}=${v}`;
        })
            .join("&");
        return visit(params);
    },
    /**
     * Property values of type array or object generate separate parameters
     * for each value of the array, or key-value-pair of the map.
     * For other types of properties this property has no effect.
     * See https://github.com/OAI/OpenAPI-Specification/blob/master/versions/3.0.0.md#encoding-object
     */
    explode(params: Record<string, any>, encoders = encodeReserved): string {
        const q = encode(encoders);
        return Object.entries(params)
            .filter(([, value]) => value !== undefined)
            .map(([name, value]) => {
            if (Array.isArray(value)) {
                return value.map(v => q `${name}=${v}`).join("&");
            }
            if (typeof value === "object") {
                return QS.explode(value, encoders);
            }
            return q `${name}=${value}`;
        })
            .join("&");
    },
    form: delimited(),
    pipe: delimited("|"),
    space: delimited("%20")
};
export class HttpError extends Error {
    status: number;
    constructor(status: number, message: string) {
        super(message);
        this.status = status;
    }
}
type PromisedApiResult<M> = M extends (...args: any) => Promise<infer T> ? T : never;
export type ApiResult<N extends keyof Api> = PromisedApiResult<Api[N]>;
export type messageTypeEnum = "Error" | "ConfigurationError" | "Information";
export type processMessages = {
    messageType?: messageTypeEnum;
    processIdentifier?: string;
    messageCode?: string;
    message?: string;
}[];
export type processInfo = {
    success: boolean;
    time?: number;
    requestId?: string;
    processMessages?: processMessages;
};
export type baseDataCruiseShips = {
    code: string;
    name: string;
}[];
export type baseDataCruisePorts = {
    code?: string;
    isDeparturePort?: boolean;
    isArrivalPort?: boolean;
}[];
export type baseDataCruisePortInfos = {
    code: string;
    name: string;
}[];
export type cabinTypeEnum = "Balcony" | "JuniorSuite" | "Interior" | "Veranda" | "Suite" | "Outside" | "Family" | "DeluxeSuite" | "PanoramaSuite" | "PanoramaBalcony" | "FamilyBalcony" | "FamilyVeranda" | "SkySuite" | "HorizonSuite" | "ViewSuite" | "SpaBalcony" | "OverseaSuite" | "JuniorSuiteVeranda" | "VerandaSuite" | "ThemeSuite" | "CombiBalcony";
export type baseDataCruiseCabinTypeInfos = {
    code: string;
    name: string;
}[];
export type baseDataTariffTypeInfos = {
    code: string;
    name: string;
};
export type baseDataCruiseDuration = {
    code: string;
    name: string;
}[];
export type baseDataCruise = {
    shipCodes: string[];
    shipInfos: baseDataCruiseShips;
    ports: baseDataCruisePorts;
    portInfos: baseDataCruisePortInfos;
    cabinTypes: cabinTypeEnum[];
    cabinTypeInfos: baseDataCruiseCabinTypeInfos;
    tariffTypes: string[];
    tariffTypeInfos: baseDataTariffTypeInfos[];
    passengers: {
        maxAdults: number;
        minAdults: number;
        maxChildren: number;
        minChildren: number;
    };
    duration: baseDataCruiseDuration;
    dateRange?: number;
};
export type baseDataFlightAirports = {
    code: string;
    name: string;
}[];
export type baseDataFlight = {
    departureAirports: string[];
    arrivalAirports: string[];
    arrivalAirportInfos: baseDataFlightAirports;
    departureAirportInfos: baseDataFlightAirports;
};
export type baseDataGeoLocationsRegionInfos = {
    code: string;
    name: string;
}[];
export type baseDataGeoLocations = {
    regions: string[];
    regionInfos: baseDataGeoLocationsRegionInfos;
};
export type priceTypeEnum = "Base" | "Board" | "EarlyBird" | "ExtraDay" | "StayPay" | "ChildReduction" | "DurationReduction" | "ChildPrice" | "OccupancyReduction" | "OccupancySupplement" | "BoardChildReduction" | "BoardSupplement" | "SelfDrivingSupplement" | "GeneralSupplement" | "GeneralDiscount" | "FuelSurcharge" | "AirPassengerDuty" | "AirportSurcharge" | "FlightSupplement" | "FlightTax" | "SecurityCharge" | "FirstMinute" | "LastMinute" | "UltraFirstMinute" | "UltraLastMinute" | "CustomPriceType1" | "CustomPriceType2" | "CustomPriceType3" | "CustomPriceType4" | "CustomPriceType5" | "CustomPriceType6" | "CustomPriceType7" | "CustomPriceType8" | "CustomPriceType9" | "CustomPriceType10" | "CustomPriceType11" | "CustomPriceType12" | "CustomPriceType13" | "CustomPriceType14" | "CustomPriceType15" | "CustomPriceType16" | "CustomPriceType17" | "CustomPriceType18" | "CustomPriceType19" | "CustomPriceType20" | "CustomPriceType21" | "CustomPriceType22" | "CustomPriceType23" | "CustomPriceType24" | "CustomPriceType25" | "CustomPriceType26" | "CustomPriceType27" | "CustomPriceType28" | "CustomPriceType29" | "CustomPriceType30" | "CustomPriceType31" | "CustomPriceType32" | "CustomPriceType33" | "CustomPriceType34" | "CustomPriceType35";
export type baseDataPriceTypeInfos = {
    code: string;
    name: string;
}[];
export type baseDataPeriods = {
    firstCheckInDate: string;
    lastCheckOutDate: string;
};
export type baseDataResponse = {
    processInfo?: processInfo;
    lang?: string;
    currency?: string;
    cruise: baseDataCruise;
    flight: baseDataFlight;
    geoLocations: baseDataGeoLocations;
    priceTypes?: priceTypeEnum[];
    priceTypeInfos?: baseDataPriceTypeInfos;
    periods: baseDataPeriods;
};
export type pagingType = {
    resultsFrom?: number;
    resultsTotal?: number;
};
export type sortCrieriaEnum = "Price" | "DepartureDate";
export type sortDirectionEnum = "Asc" | "Desc";
export type sortType = {
    criteria?: sortCrieriaEnum;
    direction?: sortDirectionEnum;
};
export type passengerRequest = {
    adults: number;
    children: number;
};
export type periodDelimiter = {
    preOffset?: number;
    date: string;
    postOffset?: number;
};
export type periodRequest = {
    start: periodDelimiter;
    end?: periodDelimiter;
    duration?: string;
};
export type geoLocationRequest = {
    regions?: string[];
};
export type flightRequest = {
    departureAirports?: string[];
    arrivalAirports?: string[];
};
export type flightsRequest = {
    outbound?: flightRequest;
    inbound?: flightRequest;
    allowOpenJaw?: boolean;
};
export type cruiseRequest = {
    shipCodes?: string[];
    journeyIdentifier?: string[];
    cabinTypes?: cabinTypeEnum[];
    tariffTypes?: string[];
};
export type compositionTypeEnum = "None" | "JourneyIdentifier" | "JourneyIdentifierPriceCategory" | "JourneyIdentifierPriceCategoryCabinType";
export type cruiseListRequest = {
    paging?: pagingType;
    sort?: sortType;
    passenger: passengerRequest;
    period: periodRequest;
    geoLocation?: geoLocationRequest;
    flight?: flightsRequest;
    cruise?: cruiseRequest;
    composition?: compositionTypeEnum;
};
export type productTypeEnum = "Cruise" | "CruisePackage";
export type serviceReferences = {
    serviceId: number;
    serviceVariantId?: number;
}[];
export type passengerTitleEnum = "H" | "D" | "K" | "B";
export type priceInformationPassengers = {
    id: number;
    amount: number;
    title?: passengerTitleEnum;
}[];
export type priceDetails = {
    priceType?: priceTypeEnum;
}[];
export type priceInformation = {
    amount: number;
    passengerPrices: priceInformationPassengers;
    priceDetails?: priceDetails;
};
export type productResponse = {
    id: number;
    productType: productTypeEnum;
    serviceReferences: serviceReferences;
    priceInformation: priceInformation;
}[];
export type flightDirectionEnum = "Outbound" | "Inbound" | "Roundtrip";
export type flightService = {
    departureAirport: string;
    arrivalAirport: string;
    departureDate: string;
    arrivalDate: string;
    productCode?: string;
    bookingClassCode?: string;
    requestCode?: string;
    direction?: flightDirectionEnum;
};
export type cruiseServiceLocation = {
    routeGroupCode?: string;
    originAirports?: string[];
    destinationAirports?: string[];
    region?: string;
};
export type cruiseVariantCabin = {
    cabinCode: string;
    cabinType: cabinTypeEnum;
    tariffType: string;
};
export type cruiseVariantBoard = {
    boardCode: string;
};
export type cruiseVariants = {
    id: number;
    cabin: cruiseVariantCabin;
    board?: cruiseVariantBoard;
}[];
export type cruiseService = {
    journeyIdentifier: string;
    startDate: string;
    endDate: string;
    packageReference?: string;
    shipCode: string;
    requestCode?: string;
    location?: cruiseServiceLocation;
    cruiseVariants: cruiseVariants;
};
export type serviceResponse = {
    id: number;
    flightService?: flightService;
    cruiseService?: cruiseService;
}[];
export type serviceTrips = {
    journeyIdentifier: string;
    name: string;
    nights: string;
    ibeBlocked?: boolean;
}[];
export type serviceInfoResponse = {
    airports: baseDataFlightAirports;
    ships: baseDataCruiseShips;
    trips: serviceTrips;
    cabins: baseDataCruiseCabinTypeInfos;
    tariffTypes: baseDataTariffTypeInfos[];
};
export type cruiseListResponse = {
    processInfo?: processInfo;
    lang?: string;
    currency?: string;
    paging?: pagingType;
    sort?: sortType;
    products: productResponse;
    services: serviceResponse;
    serviceInfos: serviceInfoResponse;
};
export class SearchserviceUrlServer {
    url: string;
    constructor({ version = "v1" }: {
        version: "dev" | "test" | "v1" | "v2";
    }) {
        this.url = `https://api.meinschiff.com/cruisesearch/${version}/`;
    }
}

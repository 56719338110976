import * as React from 'react';
import { useLocation } from 'react-router';

import { captureMessage, Severity, withSentryData } from '../lib';
import useBaseData, { BaseData, createNormalizers } from './useBaseData';
import { cruiseListRequest } from '../api';
import { useSearchStore } from '../state/search';
import { useEffect } from 'react';

function toString(val: any): string {
  return val ? `${val}` : '';
}

function toNumber(val: any): number {
  const n = parseInt(toString(val), 10);
  if (n.toString() !== val) {
    throw new Error(`${val} is not a valid number`);
  }
  return n;
}

function toArray(val: any): string[] {
  return !val || val === '' ? [] : toString(val).split(',');
}

function parseSearch(rawSearch: string, baseData: BaseData): cruiseListRequest | null {
  const search = rawSearch.replace(/^\?/, '');
  const normalize = createNormalizers(baseData);

  if (search === '') {
    return null;
  }

  try {
    const rawSearchParams = search.split('&').reduce(
      (memo, entry) => {
        const [key, value] = entry.split('=');
        memo[key] = value;
        return memo;
      },
      {} as { [key: string]: string },
    );

    return {
      passenger: {
        adults: normalize.adults(toNumber(rawSearchParams.adults)),
        children: normalize.children(toNumber(rawSearchParams.children)),
      },
      period: {
        start: {
          preOffset: 0,
          date: normalize.startDate(toString(rawSearchParams.firstCheckInDate)),
          postOffset: 0,
        },
        end: {
          preOffset: 0,
          date: normalize.endDate(toString(rawSearchParams.lastCheckOutDate)),
          postOffset: 0,
        },
        duration: normalize.duration(rawSearchParams.cruiseDuration),
      },
      geoLocation: {
        regions: normalize.regions(toArray(rawSearchParams.regions)),
      },
      flight: {
        outbound: {
          departureAirports: normalize.airports(
            toArray(rawSearchParams.departureAirports),
          ),
        },
        inbound: {
          arrivalAirports: normalize.airports(
            toArray(rawSearchParams.arrivalAirports),
          ),
        },
      },
      cruise: {
        shipCodes: normalize.ships(toArray(rawSearchParams.ships)),
        cabinTypes: normalize.cabins(toArray(rawSearchParams.cabinTypes)),
      },
    };
  } catch (err) {
    captureMessage(
      withSentryData({ severity: Severity.Warning })(
        `Could not parse search "${search}"`,
      ),
    );
    return null;
  }
}

export const SearchParametersContext = React.createContext<ReturnType<
  typeof parseSearch
> | null>(null);

export const SearchParameterProvider: React.FC = ({ children }) => {
  const { search } = useLocation();
  const baseData = useBaseData(true);
  const { setSearch } = useSearchStore();
  const searchParameters = React.useMemo(() => {
    if (!baseData || baseData instanceof Error) {
      return null;
    }

    return parseSearch(search, baseData);
  }, [search, baseData]);

  useEffect(() => {
    setSearch(searchParameters);
  }, [searchParameters, setSearch]);

  return (
    <SearchParametersContext.Provider value={searchParameters}>
      {children}
    </SearchParametersContext.Provider>
  );
};

export default function useSearchParameters() {
  return React.useContext(SearchParametersContext);
}

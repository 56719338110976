import * as React from 'react';
import { Link, LinkProps, useLocation } from 'react-router-dom';

const CruiseLink: React.FC<
  Pick<LinkProps, 'to' | 'children' | 'className' | 'replace' | 'tabIndex'> & {
    'data-testid'?: string;
  }
> = ({ to, children, className, replace, 'data-testid': testId }) => {
  const { search } = useLocation();

  return (
    <Link
      to={`${to}${search}`}
      className={className}
      replace={replace}
      data-testid={testId}
    >
      {children}
    </Link>
  );
};

export default CruiseLink;

import * as React from 'react';
import styled from 'styled-components/macro';

import { HEIGHT as HEADER_HEIGHT } from '../Header';
import {colors, useTariffs} from '../../lib';

import Table, { Tr, Td } from '../Table';
import {Option, isFilteredOption} from '../../types';

type Props = {
  active?: boolean;
  resultHint?: string;
  filteredTariffs?: Option[] | [];
};

const ResultHint = styled(Td)`
  font-size: 16px;
`;

const TarifHead = styled(Td)`
  font-size: 20px;
  font-weight: bold;
  color: ${({filtered}: {filtered?: boolean}) =>
    filtered || filtered === undefined ? 'unset' : colors.disabledGray 
  };  
`;

const CruiseTable: React.FC<Props> = React.memo(
  ({ resultHint, children, active, filteredTariffs }) => {
    const tariffs = useTariffs();

    return (
      <Table
        active={active}
        headerTopOffset={HEADER_HEIGHT}
        columns={tariffs.length + 1}
        head={
          <Tr>
            <ResultHint topBorder={false}>{resultHint}</ResultHint>
            {tariffs.map(({ name , code}) => (
              <TarifHead right
                         topBorder={false}
                         key={name}
                         filtered={isFilteredOption(code, filteredTariffs)}
              >
                {name}
              </TarifHead>
            ))}
          </Tr>
        }
      >
        {children}
      </Table>
    );
  },
);

export default CruiseTable;

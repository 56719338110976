export const TARIFFS = {
  pur: {
    code: 'PUR',
    headline: 'PUR-Tarif',
  },
  plus: {
    code: 'PLUS',
    headline: 'PLUS-Tarif',
  }
};

export const emptyText = '–';

import * as React from 'react';
import styled from 'styled-components/macro';

import Button from './Button';

const ButtonWrap = styled.div`
  display: flex;
  justify-content: center;
`;

const HeadLine = styled.h3`
  font-size: 21px;
  text-align: center;
`;

const ErrorWithReset: React.FC = () => {
  return (
    <>
      <HeadLine>Es ist ein Fehler aufgetreten.</HeadLine>
      <ButtonWrap>
        <Button href={window.location.origin}>Zurücksetzen</Button>
      </ButtonWrap>
    </>
  );
};

export default ErrorWithReset;

import * as React from 'react';

import Banner from './Banner';
import { captureException, withSentryData, Severity } from '../lib';

import ErrorWithReset from './ErrorWithReset';

type State = { error: any };

export default class ErrorBoundary extends React.Component<{}, State> {
  state: State = {
    error: null,
  };

  static getDerivedStateFromError(error: any) {
    return { error };
  }

  componentDidCatch(error: any, errorInfo: any) {
    console.log('Techniker ist informiert.');
    captureException(
      withSentryData({ severity: Severity.Critical, extras: errorInfo })(error),
    );
  }

  render() {
    if (this.state.error) {
      const fallback = (
        <Banner>
          <ErrorWithReset />
        </Banner>
      );

      return fallback;
    }

    return this.props.children;
  }
}

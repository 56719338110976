import * as React from 'react';
import { useEffect } from 'react';
import styled from 'styled-components/macro';

import { ActiveFilter, colors, useActive } from '../lib';

export const LABEL_WIDTH = 200;
export const LABEL_MIN_INNER_MARGIN = 36;
export const LABEL_MAX_INNER_MARGIN = 67;
export const LABEL_VERTICAL_PADDING = 16;
const LABEL_TOP_PADDING = 12;

type Props = {
  disabled?: boolean;
  label: string;
  render?: (
    active: boolean,
    setActive: (active: boolean) => void,
  ) => React.ReactNode;
  activeFilter?: ActiveFilter;
  newStyle?: boolean;
  onClose?: () => void;
  onEnter?: () => void;
};

const LabelWrap = styled.label`
  width: ${LABEL_WIDTH}px;
  height: ${({ newStyle }: { disabled?: boolean, newStyle?: boolean }) =>
    (newStyle) ? `70px` : '52px'
  };
`;

type LabelProps = {
  active?: boolean;
  disabled?: boolean;
  newStyle?: boolean;
};

const LabelInner = styled.span`
  position: relative;
  z-index: ${({ active, newStyle }: LabelProps) =>
    (active ? (newStyle ? 1 : 3) : 0)};
  color: ${({ disabled }: LabelProps) =>
    disabled ? colors.disabledGray : colors.grayBlue};
  display: block;
  width: ${LABEL_WIDTH + LABEL_VERTICAL_PADDING * 2}px;
  padding: ${({ active, newStyle }: LabelProps) => newStyle ?
    `${active ? '39px' : '7px'} ${LABEL_VERTICAL_PADDING}px 7px ${LABEL_VERTICAL_PADDING}px` :
    `${LABEL_TOP_PADDING}px ${LABEL_VERTICAL_PADDING}px`
  };
  margin: ${({ newStyle }: LabelProps) => (
    newStyle ? `-${LABEL_TOP_PADDING}px 0 0 -${LABEL_VERTICAL_PADDING * 2}px` :
      `-${LABEL_TOP_PADDING}px 0 0 -${LABEL_VERTICAL_PADDING}px`
  )};
  background: ${({ active, newStyle }: LabelProps) =>
    ((active || newStyle) ? 'white' : 'transparent')};
  box-shadow: ${({ active, newStyle }: LabelProps) =>
    active ? '0 6px 10px 3px rgba(46, 69, 91, 0.2)'
      : newStyle ? '0 0px 4px 0px rgba(46, 69, 91, 0.2)' : 'none'
  };
`;

const LabelTitle = styled.span`
  position: ${({ active, newStyle }: LabelProps) =>
    (active && newStyle ? 'absolute' : 'relative')
  };
  z-index: 2;
  font-size: 14px;
  display: block;
  color: ${({ disabled }: { disabled?: boolean, newStyle?: boolean }) =>
    (disabled) ? colors.disabledGray : colors.grayBlue};
  margin: ${({ newStyle }: { disabled?: boolean, newStyle?: boolean }) =>
    (newStyle) ? `0 0 ${LABEL_VERTICAL_PADDING}px -${LABEL_VERTICAL_PADDING}px`
      : '0'
  };
`;

const StyledLabel: React.FC<Props> = ({
                                        label,
                                        children,
                                        disabled,
                                        render,
                                        activeFilter: filter,
                                        newStyle,
                                        onClose,
                                        onEnter,
                                      }) => {
  const [ref, baseActive, baseSetActive] = useActive<HTMLLabelElement>({
    filter,
  });

  const active = disabled ? false : baseActive;
  const setActive = React.useCallback(
    (active: boolean) => {
      if (!disabled) {
        baseSetActive(active);
      }
    },
    [disabled, baseSetActive],
  );


  React.useEffect(() => {
    if (!active && onClose) {
      onClose();
    }
  }, [active, onClose]);

  useEffect(() => {
    if (active && onEnter) {
      onEnter();
    }
  }, [active, onEnter]);

  if (newStyle) {
    return (
      <LabelWrap ref={ref}
                 newStyle={newStyle}
      >
        <LabelTitle disabled={disabled} newStyle={newStyle} active={active}>
          {label}
        </LabelTitle>
        <LabelInner disabled={disabled}
                    active={active}
                    newStyle={newStyle}
        >
          {render ? render(active, setActive) : children}
        </LabelInner>
      </LabelWrap>
    );
  }


  return (
    <LabelWrap ref={ref}>
      <LabelInner disabled={disabled} active={active}>
        <LabelTitle>{label}</LabelTitle>
        {render ? render(active, setActive) : children}
      </LabelInner>
    </LabelWrap>
  );
};

export default StyledLabel;

import { IBE_CONTENT_API, fetch } from '../../lib';

/**
 * Typs for cruise detail pdf export
 */
export type ibeContentRegionCode = {
  regionCode: string;
  regionName: string;
};
export type ibeContentItinerary = {
  date: Date;
  departureTime: string | null;
  arrivalTime: string | null;
  countryName: string | null;
  portName: string | null;
  tenderPort: boolean;
};
export type ibeContentTripInfo = {
  transreise: boolean;
  numberOfNights: number;
  customvar6: string;
  dateTo: string;
  routeMapURL: string;
  ship: string;
  itinerary: ibeContentItinerary[];
  tripName: string;
  dateFrom: string;
  region: ibeContentRegionCode[];
  routeImage: {
    stageImageURL: string;
    smartphoneImageURL: string;
    desktopImageURL: string;
  };
};

export type ibeCabinTypesAndCategories = {
  images: any[];
  cabinCategories: ibeCabinCategory[];
  cabinTypeInterior: any[];
  exteriorType: any | null;
  interiorSizeInfo: string;
  maxOccupation: string;
  cabinTypeSpecifics: any[];
  description: string;
  categoryTypeCode: string;
  exteriorSizeInfo: any | null;
  floorplanImageURLs: any[];
  panoramas: any[];
  otdsCabinTypeName: string;
  cabinTypeName: string;
  minOccupation: string;
};

export type ibeCabinCategory = {
  cabinCategoriesSuffix: string | null;
  cabinCategoryCode: string;
  cabinCategoryEquipments: any[];
  imageURL: any;
  maxOccupation: number;
  decks: any[];
};

export async function getIbeTripInfo(tripCode: string) {
  const data = await fetch(
    `${IBE_CONTENT_API}/tripInfo?tripCode=${tripCode}`,
  ).then((response) => response.json());

  return data as ibeContentTripInfo;
}

export async function getCabinTypesAndCategories(tripCode: string) {
  const data = await fetch(
    `${IBE_CONTENT_API}/cabinTypesAndCategories?tripCode=${tripCode}`,
  ).then((response) => response.json());

  return data as ibeCabinTypesAndCategories[];
}

import * as React from 'react';
import { useRouteMatch } from 'react-router';
import styled from 'styled-components/macro';

import { MIN_PADDING, CRUISES_ROUTE } from '../../lib';
import ContentWrap, {
  Box,
  MIN_WIDTH as CONTENT_MIN_WIDTH,
} from '../ContentWrap';
import { HEIGHT as HEADER_HEIGHT } from '../Header';

import bgImg from './bg.png';

const REST_FORM_HEIGHT = 125;
const PADDING_BOTTOM = 20;
const BOX_HEIGHT = 449;

type BannerElmProps = {
  fullHeight: boolean;
};

const BannerImgMinHeight =
  BOX_HEIGHT - REST_FORM_HEIGHT + MIN_PADDING + HEADER_HEIGHT;
const BannerImg = styled.div`
  height: ${({ fullHeight }: BannerElmProps) =>
    fullHeight
      ? `calc(100vh - ${REST_FORM_HEIGHT + PADDING_BOTTOM}px)`
      : `${BannerImgMinHeight}px`};
  min-height: ${BannerImgMinHeight}px;
  margin: 0 auto;
  background-image: url('${bgImg}');
  background-size: cover;
  background-position: center center;
  transition: height 200ms;
`;

const BannerWrapMinHeight =
  BOX_HEIGHT + MIN_PADDING + PADDING_BOTTOM + HEADER_HEIGHT;
const BannerWrap = styled.div`
  position: relative;
  background-color: white;
  height: ${({ fullHeight }: BannerElmProps) =>
    fullHeight ? '100vh' : `${BannerWrapMinHeight}px`};
  transition: height 200ms;
  margin-bottom: ${({ fullHeight }: BannerElmProps) =>
    fullHeight ? '1px' : `0px`};
  min-height: ${BannerWrapMinHeight}px;
  min-width: ${CONTENT_MIN_WIDTH + MIN_PADDING * 4}px;
`;

const BannerBoxWrap = styled.div`
  position: absolute;
  height: ${BOX_HEIGHT}px;
  bottom: ${PADDING_BOTTOM}px;
  width: 100%;
  min-width: ${CONTENT_MIN_WIDTH + MIN_PADDING * 2}px;
`;

const BannerBox = styled(Box)`
  height: 100%;
`;

const Banner: React.FC = ({ children }) => {
  const match = useRouteMatch(CRUISES_ROUTE);

  return (
    <BannerWrap fullHeight={!Boolean(match)}>
      <BannerImg fullHeight={!Boolean(match)} />
      <BannerBoxWrap>
        <BannerBox>
          <ContentWrap>{children}</ContentWrap>
        </BannerBox>
      </BannerBoxWrap>
    </BannerWrap>
  );
};

export default Banner;

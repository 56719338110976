import styled from 'styled-components/macro';

import { colors } from '../lib';

type Props = {
  disabled?: boolean;
  newStyle?: boolean;
};

export default styled.input`
  width: 100%;
  font-size: 18px;
  color: ${({ disabled, newStyle }: Props) =>
    disabled ? colors.disabledGray : newStyle ? colors.grayBlue : colors.blue};
  height: 36px;
  font-weight: normal;
  border: none;
  border-bottom: ${({newStyle}: Props) => newStyle ? 'none' : '1px solid'};
  border-color: ${({ disabled }: Props) =>
    disabled ? colors.disabledGray : colors.grayBlue};
  padding: 0;
  text-align: left;
  outline: none;
  cursor: ${({ disabled }: Props) => (disabled ? 'default' : 'text')};
  &::-moz-focus-inner {
    border: 0;
  }
  &::-ms-clear {
    display: none;
  }
  &::-webkit-inner-spin-button,
   ::-webkit-calendar-picker-indicator,
   ::-webkit-search-cancel-button,
   ::-webkit-clear-button {
    display: none;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
  }
   appearance: none;
    -moz-appearance: none;
`;

import * as Sentry from '@sentry/browser';

import { SENTRY_DSN as dsn } from './env';

const enabled = process.env.NODE_ENV === 'production';

Sentry.init({
  dsn: dsn,
  enabled,
  environment: window.location.hostname,
});

export const IGNORE_ERROR = Symbol('IGNORE_ERROR');

export type SentryData = {
  extras?: { [key: string]: any };
  severity?: Sentry.Severity;
  [IGNORE_ERROR]?: boolean;
};

type StringWithSentryData = string & SentryData;
type ErrorWithSentryData = Error & SentryData;

export function withSentryData(
  data: SentryData,
): (err: Error | string) => ErrorWithSentryData | StringWithSentryData {
  return (err: Error | string) => {
    return Object.assign(err, data);
  };
}

export function captureException(
  exception: any,
  defaultSeverity: Sentry.Severity = Sentry.Severity.Error,
) {
  Sentry.withScope((scope) => {
    if (!enabled) {
      console.warn(
        `${
          exception[IGNORE_ERROR] ? 'ignored' : 'captured'
        } exception(${exception.severity || defaultSeverity}):`,
        exception,
        exception.extras,
      );
      return;
    }
    if (exception[IGNORE_ERROR]) {
      return;
    }
    scope.setLevel(exception.severity || defaultSeverity);
    scope.setExtras(exception.extras || {});
    Sentry.captureException(exception);
  });
}

export const setUser = Sentry.setUser;

export function captureMessage(
  message: any,
  defaultSeverity: Sentry.Severity = Sentry.Severity.Error,
) {
  Sentry.withScope((scope) => {
    if (!enabled) {
      console.info(
        `${
          message[IGNORE_ERROR] ? 'ignored' : 'captured'
        } message(${message.severity || defaultSeverity}):`,
        message,
        message.extras,
      );
      return;
    }
    if (message[IGNORE_ERROR]) {
      return;
    }
    scope.setLevel(message.severity || defaultSeverity);
    scope.setExtras(message.extras || {});
    Sentry.captureMessage(message);
  });
}

export const Severity = Sentry.Severity;

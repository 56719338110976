import { Dispatch, useCallback, ChangeEvent } from 'react';
import { Thunk } from 'react-hook-thunk-reducer';
import { isBefore, addDays } from 'date-fns';

import { inRange, ALL_CODE } from '../../lib';

import { SearchState } from './SearchState';
import { Options } from './SearchOptions';
import { ga4formatDateObject, trackGA4FormInteraction } from '../../tealium';

export const SET_ADULTS = Symbol('SET_ADULTS');
export const SET_CHILDREN = Symbol('SET_CHILDREN');
export const SET_FIRST_CHECK_IN_DATE = Symbol('SET_FIRST_CHECK_IN_DATE');
export const SET_LAST_CHECK_OUT_DATE = Symbol('SET_LAST_CHECK_OUT_DATE');
export const SET_CRUISE_DURATION = Symbol('SET_CRUISE_DURATION');
export const SET_ARRIVAL_AIRPORTS = Symbol('SET_ARRIVAL_AIRPORTS');
export const SET_DEPARTURE_AIRPORTS = Symbol('SET_DEPARTURE_AIRPORTS');
export const SET_SHIPS = Symbol('SET_SHIPS');
export const SET_REGIONS = Symbol('SET_REGIONS');
export const SET_CABIN_TYPES = Symbol('SET_CABIN_TYPES');
export const TOGGLE_ARRIVAL_LIKE_DEPARTURE = Symbol(
  'SET_ARRIVAL_LIKE_DEPARTURE',
);
export const RESET = Symbol('RESET');

type SetAdultsAction = {
  type: typeof SET_ADULTS;
  payload: SearchState['adults'];
};

type SetChildrenAction = {
  type: typeof SET_CHILDREN;
  payload: SearchState['children'];
};

type SetCheckInDateAction = {
  type: typeof SET_FIRST_CHECK_IN_DATE;
  payload: SearchState['firstCheckInDate'];
};

type SetLastCheckOutDateAction = {
  type: typeof SET_LAST_CHECK_OUT_DATE;
  payload: SearchState['lastCheckOutDate'];
};

type SetCruiseDurationAction = {
  type: typeof SET_CRUISE_DURATION;
  payload: SearchState['cruiseDuration'];
};

type SetDepartureAirportsAction = {
  type: typeof SET_DEPARTURE_AIRPORTS;
  payload: SearchState['departureAirports'];
};

type SetArrivalAirportsAction = {
  type: typeof SET_ARRIVAL_AIRPORTS;
  payload: SearchState['arrivalAirports'];
};

type SetShipsAction = {
  type: typeof SET_SHIPS;
  payload: SearchState['ships'];
};

type SetRegionsAction = {
  type: typeof SET_REGIONS;
  payload: SearchState['regions'];
};

type SetCabinTypesAction = {
  type: typeof SET_CABIN_TYPES;
  payload: SearchState['cabinTypes'];
};

type ToggleArrivalLikeDepartureAction = {
  type: typeof TOGGLE_ARRIVAL_LIKE_DEPARTURE;
};

type Reset = {
  type: typeof RESET;
  payload: SearchState;
}

export type SearchAction =
  | SetAdultsAction
  | SetChildrenAction
  | SetCheckInDateAction
  | SetLastCheckOutDateAction
  | SetCruiseDurationAction
  | SetDepartureAirportsAction
  | SetArrivalAirportsAction
  | ToggleArrivalLikeDepartureAction
  | SetShipsAction
  | SetRegionsAction
  | SetCabinTypesAction
  | Reset;

type MultiSelectAction =
  | SetDepartureAirportsAction
  | SetArrivalAirportsAction
  | SetShipsAction
  | SetRegionsAction
  | SetCabinTypesAction;

function parseIntOrEmpty(value: ChangeEvent<HTMLInputElement> | number | '') {
  switch (typeof value) {
    case 'string':
    case 'number':
      return value;
    default:
      return value.target.value === '' ? '' : parseInt(value.target.value, 10);
  }
}

type MultiSelectStateKey =
  | 'departureAirports'
  | 'arrivalAirports'
  | 'ships'
  | 'regions'
  | 'cabinTypes';

/* any-casting required until https://github.com/microsoft/TypeScript/issues/27808 */
function createMultiSelectAction<A extends MultiSelectAction>(
  value: A['payload'][0],
  type: A['type'],
  key: MultiSelectStateKey,
  options: A['payload'],
): Thunk<SearchState, A> {
  return (dispatch, getState) => {
    const state = getState();

    const allSelected = state[key].length === 0;

    if (value.code === ALL_CODE) {
      if (allSelected) {
        return;
      }

      dispatch({
        type,
        payload: [],
      } as any);
    } else if (allSelected) {
      dispatch({
        type,
        payload: [value],
      } as any);
    } else if (state[key].length === 1 && state[key][0] === value) {
      dispatch({
        type,
        payload: [],
      } as any);
    } else if (state[key].includes(value)) {
      dispatch({
        type,
        payload: state[key].filter((v) => v !== value),
      } as any);
    } else if (state[key].length + 1 === options.length) {
      dispatch({
        type,
        payload: [],
      } as any);
    } else {
      dispatch({
        type,
        payload: state[key].concat(value),
      } as any);
    }
  };
}

export default function useSearchActions(
  dispatch: Dispatch<SearchAction | Thunk<SearchState, SearchAction>>,
  {
    cruise: {
      cabinTypes,
      ships,
      passengers: { minAdults, maxAdults, minChildren, maxChildren },
      dateRange,
    },
    flight: { departureAirports },
    geoLocations: { regions },
  }: Options,
) {
  const setAdults = useCallback(
    (valueOrEvent: ChangeEvent<HTMLInputElement> | number | '') => {
      const value = parseIntOrEmpty(valueOrEvent);

      const payload = value === '' ? value : inRange(value, [minAdults, maxAdults]);
      trackGA4FormInteraction('kreuzfahrtsuche', 'filled.adults.' + payload);
      return dispatch({
        type: SET_ADULTS,
        payload,
      });
    },
    [dispatch, minAdults, maxAdults],
  );

  const setChildren = useCallback(
    (valueOrEvent: ChangeEvent<HTMLInputElement> | number | '') => {
      const value = parseIntOrEmpty(valueOrEvent);
      const payload = value === '' ? value : inRange(value, [minChildren, maxChildren]);
      trackGA4FormInteraction('kreuzfahrtsuche', 'filled.children.' + payload);

      return dispatch({
        type: SET_CHILDREN,
        payload,
      });
    },
    [dispatch, minChildren, maxChildren],
  );

  const setFirstCheckInDate = useCallback(
    (value: SearchState['firstCheckInDate']) => {
      dispatch((_, getState) => {
        const { lastCheckOutDate } = getState();
        trackGA4FormInteraction('kreuzfahrtsuche', 'filled.first-check-in-date.' + ga4formatDateObject(value));

        dispatch({
          type: SET_FIRST_CHECK_IN_DATE,
          payload: value,
        });

        if (isBefore(lastCheckOutDate, value)) {
          trackGA4FormInteraction('kreuzfahrtsuche', 'filled.last-check-out-date.' + ga4formatDateObject(value));

          dispatch({
            type: SET_LAST_CHECK_OUT_DATE,
            payload: value,
          });
        }

        const maxCheckout = addDays(value, dateRange);
        if (isBefore(maxCheckout, lastCheckOutDate)) {
          trackGA4FormInteraction('kreuzfahrtsuche', 'filled.last-check-out-date.' + ga4formatDateObject(maxCheckout));
          dispatch({
            type: SET_LAST_CHECK_OUT_DATE,
            payload: maxCheckout,
          });
        }
      });
    },
    [dispatch, dateRange],
  );

  const setLastCheckoutDate = useCallback(
    (value: SearchState['lastCheckOutDate']) => {
      trackGA4FormInteraction('kreuzfahrtsuche', 'filled.last-check-out-date.' + ga4formatDateObject(value));
      dispatch({
        type: SET_LAST_CHECK_OUT_DATE,
        payload: value,
      });
    },
    [dispatch],
  );

  const setCruiseDuration = useCallback(
    (value: SearchState['cruiseDuration']) => {
      trackGA4FormInteraction('kreuzfahrtsuche', 'filled.duration.' + value.code);
      dispatch({
        type: SET_CRUISE_DURATION,
        payload: value,
      });
    },
    [dispatch],
  );

  const setDepartureAirport = useCallback(
    (value: SetDepartureAirportsAction['payload'][0]) => {
      trackGA4FormInteraction('kreuzfahrtsuche', 'click.multi-select.departure-airports.' + value.code);
      dispatch(
        createMultiSelectAction<SetDepartureAirportsAction>(
          value,
          SET_DEPARTURE_AIRPORTS,
          'departureAirports',
          departureAirports,
        ),
      );
    },
    [dispatch, departureAirports],
  );

  const setArrivalAirport = useCallback(
    (value: SetArrivalAirportsAction['payload'][0]) => {
      trackGA4FormInteraction('kreuzfahrtsuche', 'click.multi-select.arrival-airports.' + value.code);
      dispatch(
        createMultiSelectAction<SetArrivalAirportsAction>(
          value,
          SET_ARRIVAL_AIRPORTS,
          'arrivalAirports',
          departureAirports,
        ),
      );
    },
    [dispatch, departureAirports],
  );

  const setShips = useCallback(
    (value: SetShipsAction['payload'][0]) => {
      trackGA4FormInteraction('kreuzfahrtsuche', 'click.multi-select.ships.' + value.code);
      dispatch(
        createMultiSelectAction<SetShipsAction>(
          value,
          SET_SHIPS,
          'ships',
          ships,
        ),
      );
    },
    [dispatch, ships],
  );

  const setRegions = useCallback(
    (value: SetRegionsAction['payload'][0]) => {
      trackGA4FormInteraction('kreuzfahrtsuche', 'click.multi-select.regions.' + value.code);
      dispatch(
        createMultiSelectAction<SetRegionsAction>(
          value,
          SET_REGIONS,
          'regions',
          regions,
        ),
      );
    },
    [dispatch, regions],
  );

  const setCabinTypes = useCallback(
    (value: SetCabinTypesAction['payload'][0]) => {
      trackGA4FormInteraction('kreuzfahrtsuche', 'click.multi-select.cabin-types.' + value.code);
      dispatch(
        createMultiSelectAction<SetCabinTypesAction>(
          value,
          SET_CABIN_TYPES,
          'cabinTypes',
          cabinTypes,
        ),
      );
    },
    [dispatch, cabinTypes],
  );

  const toggleArrivalLikeDeparture = useCallback(() => {
    dispatch({
      type: TOGGLE_ARRIVAL_LIKE_DEPARTURE,
    });
  }, [dispatch]);

  const reset = useCallback((value: SearchState) => {
    dispatch({
      type: RESET,
      payload: value,
    });
  }, [dispatch]);

  return {
    setAdults,
    setChildren,
    setFirstCheckInDate,
    setDepartureAirport,
    setArrivalAirport,
    setLastCheckoutDate,
    setCruiseDuration,
    setShips,
    setRegions,
    setCabinTypes,
    toggleArrivalLikeDeparture,
    reset,
  };
}

import * as React from 'react';
import styled from 'styled-components/macro';

import { ArrowRight, colors, INFONET_BACKLINK } from '../../lib';
import Fixed from '../Fixed';

import Logo from './logo.svg';
import { trackGA4ClickEvent } from '../../tealium';

export const HEIGHT = 97;

const MeinSchiff = styled.img`
  margin: 26px 0 15px;
  display: inline-block;
  width: 350px;
`;

const InfonetLink = styled.a`
  font-weight: bold;
  float: right;
  font-size: 24px;
  text-decoration: none;
  margin-top: 34px;
`;

const StyledArrowRight = styled(ArrowRight)`
  top: 2px;
  position: relative;
`;

const WrapperBg = styled(Fixed)`
  height: ${HEIGHT}px;
  background: ${colors.teal};
`;

export default function Header() {
  return (
    <WrapperBg>
      <a href="/" onClick={() => {
        trackGA4ClickEvent('header.logo.mein-schiff');
      }}>
        <MeinSchiff src={Logo} />
      </a>
      <InfonetLink href={INFONET_BACKLINK}>
        Infonet <StyledArrowRight />
      </InfonetLink>
    </WrapperBg>
  );
}

import * as React from 'react';
import { useRouteMatch } from 'react-router';

import { CRUISES_ROUTE } from '../../lib';
import Modal from '../Modal';

import CruiseDetailsProvider from './CruiseDetailsProvider';

const CruiseDetailsContainer: React.FC = () => {
  const match = useRouteMatch<{ detail: string }>(`${CRUISES_ROUTE}/:detail`);

  return (
    <>
      <Modal active={Boolean(match)} to={CRUISES_ROUTE} tabIndex={-1} />
      {match ? <CruiseDetailsProvider params={match.params.detail} /> : null}
    </>
  );
};

export default CruiseDetailsContainer;

import { registerCanceler } from '../useAsyncEffect';
import { NETMATCH_API, fetch, withAbort } from '..';

export type UserData = {
  lastName: string;
  agencyNumber: number;
  firstName?: string;
  addressLine1?: string;
  addressLine2?: string;
  postalCode?: string;
  city?: string;
  country?: string;
  email?: string;
  telephone?: string;
  mobileNumber?: string;
  paymentType?: number;
  termOfPayment?: number;
  subAllotmentCodes?: any;
};

function isUserData(data: any): data is UserData {
  return data && typeof data === 'object' && typeof data.lastName === 'string';
}

export default async function getUserData(
  userId: string,
  onCancel: registerCanceler,
) {
  const data = await fetch(
    `${NETMATCH_API}/${userId}`,
    withAbort(onCancel),
  ).then((response) => response.json());

  if (!isUserData(data)) {
    throw new Error('Unexpected response object from netmatch');
  }

  return data;
}

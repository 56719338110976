export default function getCookieValue(name: string) {
  var found = document.cookie
    .split(';')
    .filter((c) => c.trim().split('=')[0] === name);

  if (found.length !== 1) {
    throw new Error(`Could not find Cookie with name ${name}`);
  }

  return found[0].split('=')[1];
}

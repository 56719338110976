import pdfMake, { Margins, PageOrientation, PageSize } from 'pdfmake/build/pdfmake';
import { Cruise as CruiseType } from '../Types';

import { TARIFFS } from './constants';
import tariffTable from './tariffTable';
import { format } from '../../../lib';

import fonts from './fonts';
import images from './images';

pdfMake.fonts = fonts;
let plusTariffPageIndex = -1;

const docDefinition = (cruises: CruiseType[]) => ({
  pageOrientation: 'LANDSCAPE' as PageOrientation,
  pageSize: 'A4' as PageSize,
  pageMargins: [20, 60, 20, 50] as Margins,
  defaultStyle: {
    font: 'SourceSansPro',
  },
  info: {
    title: 'TUI Cruises - Tariftabelle',
    author: 'TUI Cruises',
  },
  // TDocumentHeaderFooterFunction
  header: (currentPage: number) => [
    {
      margin: [28, 20, 20, 0],
      alignment: 'justify',
      columns: [
        {
          text: plusTariffPageIndex >= 0 && currentPage >= plusTariffPageIndex
            ? TARIFFS.plus.headline
            : TARIFFS.pur.headline,
          style: 'header'
        },
        {
          image: 'myShip',
          width: 149,
        }
      ]
    },
  ],
  pageBreakBefore: (currentNode: any) => {
    if (currentNode.id === `${TARIFFS.plus.code}-table`) {
      // notify header function about tariff change (change header headline)
      plusTariffPageIndex = currentNode.startPosition.pageNumber + 1;
      return true;
    }
    return false;
  },
  content: [
    {
      layout: 'cruiseTable',
      id: `${TARIFFS.pur.code}-table`,
      table: {
        widths: [60, 30, '*', 'auto', 55, 55, 60, 60, 60, 65],
        headerRows: 2,
        body: tariffTable(TARIFFS.pur.code, cruises)
      }
    },
    {
      // layout: 'cruiseTablePlus',
      layout: 'cruiseTable', // TUICUNIT-1737
      id: `${TARIFFS.plus.code}-table`,
      table: {
        // widths: [60, 30, '*', 'auto', 51, 51, 51, 53, 51, 51, 51, 53],
        widths: [60, 30, '*', 'auto', 55, 55, 60, 60, 60, 65],  // TUICUNIT-1737
        headerRows: 2,
        body: tariffTable(TARIFFS.plus.code, cruises)
      }
    }
  ],
  footer: (currentPage: number, pageCount: number) => [
    {
      alignment: 'justify',
      columns: [
        { text: `Stand: ${format(new Date(), 'dd.MM.yyyy, HH:mm')} Uhr`, style: 'footerText', absolutePosition: { x: 32, y: 18 } },
        { text: `${currentPage} / ${pageCount}`, style: 'footerText', absolutePosition: { x: 414, y: 18 } },
        { image: 'tuic', width: 73, absolutePosition: { x: 744, y: 10 } }
      ]
    }
  ],
  styles: {
    header: {
      color: '#253e6a',
      fontSize: 18,
      bold: true,
    },
    footerText: {
      color: '#929eb4',
      fontSize: 6,
    },
    mainTableHead: {
      color: '#2e455b',
      fontSize: 12,
      bold: true,
      alignment: 'right',
      margin: [5 , 2, 6, 2],
    },
    subTableHead: {
      fontSize: 8,
      color: '#929eb4',
      alignment: 'right',
    },
    entry: {
      color: '#253e6a',
      fontSize: 8,
      alignment: 'right',
      bold: true,
      margin: [0 , 2, 0, 2],
    },
    entrySmall: {
      color: '#253e6a',
      fontSize: 6,
      alignment: 'left',
    },
    empty: {
      color: '#253e6a',
      fontSize: 8,
      alignment: 'center',
      margin: [0 , 2, 0, 2],
    }
  },
  images,
});

export default docDefinition;

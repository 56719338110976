import { format as f } from 'date-fns/esm';
import locale from 'date-fns/locale/de';

export const DATE_FORMAT = 'dd.MM.yyyy';
const ISO_DATE_FORMAT = 'yyy-LL-dd';

export function formatISO(date: number | Date) {
  return f(date, ISO_DATE_FORMAT);
}

export function format(date: number | Date, format?: string) {
  return f(date, format || DATE_FORMAT, { locale });
}


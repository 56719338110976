import { useEffect } from 'react';
import { captureException } from './sentry';

export type registerCanceler = (canceler: () => void) => void;

function noop() {
  /* noop */
}

export default function useAsyncEffect(
  effect: (onUnmount: registerCanceler) => Promise<any>,
  deps?: readonly any[],
) {
  useEffect(() => {
    let cancel = noop;
    effect((c) => (cancel = c)).catch(captureException);

    return () => {
      cancel();
    };
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, deps);
}

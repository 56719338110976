import * as React from 'react';
import getCookieValue from './getCookieValue';
import getUserData, { UserData } from './getUserData';
import { AUTH_COOKIE_NAME, AUTH_ENDPOINT } from '../env';
import useAsyncEffect from '../useAsyncEffect';
import { setUser as setSentryUser } from '../sentry';
import { isAbort } from '../fetch';

type ErrorWithResponseStatus = Error & {
  response: { status: number };
};

function isNetworkError(err: any): err is ErrorWithResponseStatus {
  return err.response && err.response.status >= 400;
}

const AuthContext = React.createContext<UserData | null>(null);

const AuthProvider: React.FC = ({ children }) => {
  const [user, setUser] = React.useState<UserData | null>(null);

  useAsyncEffect(async (onUnmount) => {
    try {
      const userId = getCookieValue(AUTH_COOKIE_NAME);
      const userData = await getUserData(userId, onUnmount);
      const { agencyNumber } = userData;
      setSentryUser({ agencyNumber });
      setUser(userData);
    } catch (err) {
      /* Not testable and should not happen since the provider is always rendered */
      /* istanbul ignore if */
      if (isAbort(err)) {
        return;
      }

      const loginPage = `${AUTH_ENDPOINT}${
        isNetworkError(err) ? `#e${err.response.status}` : ''
      }`;

      (window.location as any) = loginPage;
    }
  }, []);

  return <AuthContext.Provider value={user}>{children}</AuthContext.Provider>;
};

export function useUser(): UserData;
export function useUser(force: true): UserData | null;
export function useUser(force?: true): UserData | null {
  const user = React.useContext(AuthContext);

  /* This should never happen unless the component tree is fundamentally wrong */
  /* istanbul ignore next */
  if (force !== true && !user) {
    throw new Error(`Could not find user`);
  }

  return user;
}

/* MockAuthProvider is used to bypass Auth on development stage.
   This is required since it runs on cellular.de subdomain and can
   not access the correct cookie.
   Since it's not used in production we ignore it in test coverage. */
/* istanbul ignore next */
const MockAuthProvider: React.FC = ({ children }) => {
  return (
    <AuthContext.Provider
      value={{
        firstName: 'Reisebüro',
        lastName: 'MockAgency',
        agencyNumber: 1,
        addressLine1: 'Mustermannstraße 22a',
        postalCode: '123654',
        city: 'Musterstadt',
        telephone: '+49 5622 5415 456',
        email: 'Reisebüro.Mustermann@web.de',
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
const AuthProviderSwitcher: React.FC = ({ children }) => {
  /* istanbul ignore next */
  const Provider =
    process.env.REACT_APP_ENV !== 'development'
      ? AuthProvider
      : MockAuthProvider;

  return <Provider>{children}</Provider>;
};

export default AuthProviderSwitcher;

import { useState, useEffect } from 'react';

export default function useThrottled<T extends any[]>(
  allValues: T | null,
): T | null {
  const [values, setValues] = useState<T | null>(null);
  useEffect(() => {
    if (!allValues) {
      setValues(allValues);
      return;
    }

    let id: number;
    let i = 10;
    const throttled = () => {
      setValues(allValues.slice(0, i) as T);

      if (allValues.length > i) {
        i += 10;
        id = setTimeout(throttled, 10);
      }
    };

    throttled();

    return () => {
      clearTimeout(id);
    };
  }, [allValues]);

  return values;
}

import * as React from 'react';
import styled from 'styled-components/macro';

import { usePriceFormat } from '../PriceSwitch';
import { Title } from '../Table';

import { Cabin, isCabinWithFlight, isCabinWithFlightOnly } from './Types';
import byAmount from './sortByAmount';

type PriceProps = {
  bold?: boolean;
};
const Price = styled(Title)`
  font-weight: ${({ bold }: PriceProps) => (bold ? 'bold' : 'normal')};
  height: 28px;
  line-height: 28px;
`;

type PriceWithFlightProps = {
  children: string;
};
function fontSizeByContentLength(length: number) {
  return 16 - Math.max(length - 23, 0);
}
const PriceWithFlight = styled.span`
  display: block;
  height: 22px;
  white-space: nowrap;
  line-height: 22px;
  font-size: ${({ children }: PriceWithFlightProps) =>
    fontSizeByContentLength(children.length)}px;
`;

type Props = PriceProps & {
  cabin: Cabin;
  fixedHeight?: boolean;
};

const CabinPrice: React.FC<Props> = React.memo(
  ({ bold, cabin, fixedHeight }) => {
    const formatPrice = usePriceFormat();

    if (isCabinWithFlight(cabin)) {
      const price = formatPrice(cabin.pricesWithFlight.sort(byAmount)[0]);
      return (
        <>
          <Price bold={bold}>ab {formatPrice(cabin.price)}</Price>
          <PriceWithFlight>{`inkl. Flug ab ${price}`}</PriceWithFlight>
        </>
      );
    }

    if (isCabinWithFlightOnly(cabin)) {
      return (
        <>
          <Price bold={bold}>
            ab {formatPrice(cabin.pricesWithFlight.sort(byAmount)[0])}
          </Price>
          <PriceWithFlight>inkl. Flug</PriceWithFlight>
        </>
      );
    }

    return (
      <>
        <Price bold={bold}>ab {formatPrice(cabin.price)}</Price>
        {fixedHeight ? <PriceWithFlight children="" /> : null}
      </>
    );
  },
);

export default CabinPrice;

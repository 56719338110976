import {
  Api,
  baseDataResponse,
  cruiseListRequest,
  cruiseListResponse,
  serviceInfoResponse,
} from './client';
import { fetch, CRUISESEARCH_API } from '../lib';

function getApi(fetchOpts: RequestInit) {
  return new Api({
    baseUrl: CRUISESEARCH_API,
    fetch,
    ...fetchOpts,
  });
}

type FetchOpts = Pick<RequestInit, 'signal'>;

export type BaseDataResponse = baseDataResponse;
export function getBaseData({ signal }: FetchOpts) {
  return getApi({ signal }).getBaseData();
}

export type CruiseListRequest = Omit<cruiseListRequest, 'sort' | 'composition'>;
export type ServiceInfoResponse = serviceInfoResponse;
export type CruiseListResponse = cruiseListResponse;
export function postCruiseList(
  request: CruiseListRequest,
  { signal }: FetchOpts,
) {
  return getApi({ signal }).postCruiseList(request);
}

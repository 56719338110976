import { UserData } from '../../../lib/auth/getUserData';

const MAX_EMAIL_LENGTH = 30;

export type contactBox = {
  text: any[];
  extraSpace: number;
};

export default function prepareContactText(userData: UserData): contactBox {
  let extraSpace = 0;
  let name = userData.lastName + '\n' + userData.firstName;

  let eMail = `${userData.email}`;
  if (eMail.length > MAX_EMAIL_LENGTH) {
    let dotIndex = eMail.indexOf('.', MAX_EMAIL_LENGTH);
    if (dotIndex > MAX_EMAIL_LENGTH + 10) {
      eMail = eMail.replace('@', '@\n');
      extraSpace++;
    } else {
      let split = eMail.substring(dotIndex, eMail.length);
      eMail = eMail.slice(0, dotIndex);
      eMail = `${eMail}\n${split}`;
      extraSpace++;
    }
  }

  return {
    text: [
      { text: name + '\n' },
      { text: userData.addressLine1 + '\n' },
      { text: userData.postalCode + ' ' },
      { text: userData.city + '\n\n' },
      { text: 'Tel.: ', bold: true },
      { text: userData.telephone + '\n' },
      { text: 'E-Mail: ', bold: true },
      { text: eMail },
    ],
    extraSpace: extraSpace,
  };
}

import * as React from 'react';

import InputLabel from './InputLabel';
import Input from './Input';

type InputSkeletonProps = {
  label: string;
};

const InputSkeleton: React.FC<InputSkeletonProps> = ({ label }) => {
  return (
    <InputLabel label={label} disabled>
      <Input disabled />
    </InputLabel>
  );
};

export default InputSkeleton;

const colors = {
  gray: '#d8d8d8',
  teal: '#85cee4',
  blue: '#253e6a',
  lightBlue: '#f4fafc',
  slightlyLessLightLightBlue: '#e7f5fa',
  grayBlue: '#929eb4',
  disabledGray: '#dbdee6',
  darkBlue: '#2e455b',
  lightGrayBlue: '#c8ced9',
  red: '#da2128',
};

export default colors;

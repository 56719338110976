import {UAParser} from 'ua-parser-js';

export const userAgent = new UAParser(navigator.userAgent);

export function isBrowserX(x: string) {
  return userAgent.getBrowser().name === x;
}

export function isSafari() {
  return isBrowserX('Safari');
}

export function isIE() {
  // IE11: "Mozilla/5.0 (Windows NT 10.0; WOW64; Trident/7.0; .NET4.0C; .NET4.0E; rv:11.0) like Gecko"
  // EDGE: "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/70.0.3538.102 Safari/537.36 Edge/18.18362"
  return isBrowserX('Edge') || isBrowserX('IE');
}

export function isOpera() {
  return isBrowserX('Opera');
}

export function isChrome() {
  return isBrowserX('Chrome');
}

export function isChromium() {
  return isBrowserX('Chromium');
}

export function isSeamonkey() {
  return isBrowserX('Seamonkey');
}

export function isFirefox() {
  return isBrowserX('Firefox');
}



import { SearchState } from './SearchState';
import {
  SearchAction,
  SET_ADULTS,
  SET_CHILDREN,
  SET_FIRST_CHECK_IN_DATE,
  SET_LAST_CHECK_OUT_DATE,
  SET_CRUISE_DURATION,
  SET_DEPARTURE_AIRPORTS,
  SET_ARRIVAL_AIRPORTS,
  SET_SHIPS,
  SET_REGIONS,
  SET_CABIN_TYPES,
  TOGGLE_ARRIVAL_LIKE_DEPARTURE,
  RESET
} from './useSearchActions';

export default function searchReducer(
  state: SearchState,
  action: SearchAction,
): SearchState {
  switch (action.type) {
    case SET_ADULTS:
      return { ...state, adults: action.payload };
    case SET_CHILDREN:
      return { ...state, children: action.payload };
    case SET_FIRST_CHECK_IN_DATE:
      return { ...state, firstCheckInDate: action.payload };
    case SET_LAST_CHECK_OUT_DATE:
      return { ...state, lastCheckOutDate: action.payload };
    case SET_CRUISE_DURATION:
      return { ...state, cruiseDuration: action.payload };
    case SET_DEPARTURE_AIRPORTS:
      return { ...state, departureAirports: action.payload };
    case SET_ARRIVAL_AIRPORTS:
      return { ...state, arrivalAirports: action.payload };
    case SET_SHIPS:
      return { ...state, ships: action.payload };
    case SET_REGIONS:
      return { ...state, regions: action.payload };
    case SET_CABIN_TYPES:
      return { ...state, cabinTypes: action.payload };
    case TOGGLE_ARRIVAL_LIKE_DEPARTURE:
      return { ...state, arrivalLikeDeparture: !state.arrivalLikeDeparture };
    case RESET:
      return { ...action.payload};
  }
}

import * as React from 'react';
import { useLocation } from 'react-router';

import { CRUISES_ROUTE } from '../../lib';
import Button from '../Button';

import { SearchState, serialize } from './SearchState';

type Props = {
  state: SearchState;
  tabIndex?: number;
  onClick?: () => void;
};

const Submit: React.FC<Props> = ({ state , tabIndex, onClick}) => {
  const { search } = useLocation();
  const newSearch = `?${serialize(state)}`;

  return (
    <Button
      onClick={onClick}
      disabled={search === newSearch}
      {...{tabIndex}}
      role="button"
      to={`${CRUISES_ROUTE}${newSearch}`}
    >
      Reisen anzeigen
    </Button>
  );
};

export default Submit;

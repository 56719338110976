import * as React from 'react';
import styled from 'styled-components/macro';

import { useActive, colors, Ticked } from '../../lib';
import { LABEL_WIDTH } from '../InputLabel';

type Props = {
  onChange: (ev: React.ChangeEvent<HTMLInputElement>) => void;
  value: boolean;
  tabIndex?: number;
};

type WrapperProps = {
  active?: boolean;
  disabled?: boolean;
};

export const Wrapper = styled.label`
  float: right;
  margin-top: 12px;
  width: ${LABEL_WIDTH}px;
  font-size: 14px;
  line-height: 27px;
  color: ${({ active, disabled }: WrapperProps) =>
    disabled ? colors.disabledGray : active ? colors.blue : colors.grayBlue};
  cursor: ${({ disabled }: WrapperProps) => (disabled ? 'default' : 'pointer')};
  > span {
    width: ${({ active, disabled }: WrapperProps) =>
      !disabled && active ? 25 : 23}px;
    height: ${({ active, disabled }: WrapperProps) =>
      !disabled && active ? 25 : 23}px;
    margin: ${({ active, disabled }: WrapperProps) =>
      !disabled && active ? '0 8px 0 0' : '1px 9px 1px 1px'};
    padding: ${({ active, disabled }: WrapperProps) =>
      !disabled && active ? '1px' : '0'};
    border-color: ${({ active, disabled }: WrapperProps) =>
      disabled ? colors.disabledGray : active ? colors.blue : colors.grayBlue};
  }
  &:hover {
    color: ${({ disabled }: WrapperProps) =>
      disabled ? colors.disabledGray : colors.blue};

    > span {
      width: ${({ disabled }: WrapperProps) => (!disabled ? 25 : 23)}px;
      height: ${({ disabled }: WrapperProps) => (!disabled ? 25 : 23)}px;
      margin: ${({ disabled }: WrapperProps) =>
        !disabled ? '0 8px 0 0' : '1px 9px 1px 1px'};
      padding: ${({ disabled }: WrapperProps) => (!disabled ? '1px' : '0')};
      border-color: ${({ disabled }: WrapperProps) =>
        disabled ? colors.disabledGray : colors.blue};
    }
  }
`;

const StealthCheckbox = styled.input`
  opacity: 0;
  width: 0;
`;

type CheckBoxProps = {
  className?: string;
  ticked?: boolean;
};

const CheckBoxBorder = styled.span`
  display: block;
  float: left;
  overflow: hidden;
  border: 1px solid ${colors.grayBlue};
`;
export const Checkbox: React.FC<CheckBoxProps> = ({ ticked }) => {
  return (
    <CheckBoxBorder>
      {ticked ? <Ticked tabIndex={-1} focusable='false'/> : null}
    </CheckBoxBorder>
  );
};

const ArrivalLikeDeparture: React.FC<Props> = ({ onChange, value , tabIndex}) => {
  const [ref, active] = useActive<HTMLLabelElement>({ mouse: false });

  return (
    <Wrapper ref={ref} active={active}>
      <Checkbox ticked={value} />
      Wie Hinflug
      <StealthCheckbox type="checkbox" onChange={onChange} {...{tabIndex}}/>
    </Wrapper>
  );
};

export default ArrivalLikeDeparture;

import pdfMake, {
  Margins,
  PageOrientation,
  PageSize,
} from 'pdfmake/build/pdfmake';

import { format } from '../../../lib';
import { formatPrice } from '../../PriceSwitch';

import { NightsTablelayout, IbeNightsTable } from './nightsTable';

import fonts from './fonts';
import images from './images';
import { cabinsTable, CabinsTablelayout } from './cabinsTable';
import {
  ibeContentTripInfo,
  ibeCabinTypesAndCategories,
} from '../../../api/ibeApi';
import { TypeYTdiscount } from '../../../api/ytDiscount';
import { Cruise } from '../../CruiseList/Types';
import { contactBox } from './contactBox';
import { getShipIconByShipID } from './myShipIcons';

pdfMake.fonts = fonts;

export const emptyText = '–';

// margin: [left, top, right, bottom]

// TUICUNIT-2570
const hasSomeTender = (tripInfo: ibeContentTripInfo) => {
  const tender = (tripInfo && Array.isArray(tripInfo.itinerary)) ? tripInfo.itinerary.some(item => item.tenderPort === true) : false;
  if (tender) {
    return [{
      width: 11,
      height: 11,
      margin: [ 2, 0, 0, 0 ],
      svg:
        '<svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0" y="0" viewBox="0 0 70.866 70.866" fill="#253e6a" style="enable-background:new 0 0 70.8661423 70.8661423" xml:space="preserve"><path d="M35.434.007C15.855.007-.004 15.877-.004 35.435c0 19.559 15.86 35.428 35.438 35.428 19.569 0 35.428-15.869 35.428-35.428C70.862 15.876 55.003.007 35.434.007zm-.003 66.676c-17.259 0-31.248-13.979-31.248-31.248 0-17.249 13.989-31.248 31.248-31.248 17.25 0 31.248 13.999 31.248 31.248 0 17.27-13.999 31.248-31.248 31.248z"/><path d="M54.286 39.618c-.65-.54-1.59-.57-2.27-.07l-6.97 4.92c-.53.36-.82.98-.8 1.6.03.63.37 1.23.92 1.56l1.18.68c-1.87 2.02-4.8 2.91-8.42 3.22v-21.07h5.67c1.36 0 2.49-1.14 2.49-2.51 0-1.38-1.13-2.51-2.49-2.51h-5.67v-2.6c1.59-.33 2.8-1.69 2.8-3.37V17.7c0-1.92-1.54-3.52-3.469-3.52h-3.63c-1.96 0-3.49 1.6-3.49 3.52v1.77c0 1.68 1.19 3.04 2.79 3.37v2.6h-5.65c-1.37 0-2.5 1.13-2.5 2.51 0 1.37 1.13 2.51 2.5 2.51h5.65v21.068c-3.62-.31-6.55-1.2-8.4-3.22l1.17-.7c.55-.32.9-.91.93-1.54.01-.62-.28-1.23-.79-1.6l-6.989-4.93c-.69-.47-1.61-.44-2.26.08s-.88 1.42-.55 2.18l1.91 4.67c2.43 8.27 10.53 10.32 17.48 10.22h.489c6.86 0 14.63-2.13 17.01-10.23l1.909-4.64c.32-.78.1-1.68-.55-2.2"/></svg>',
    }, {
      width: 240,
      margin: [11, 1, 0, 0],
      text: 'Tenderhafen, das Schiff liegt vor Anker und nicht an der Pier.',
      style: 'smallText'
    }];
  } else {
    return [];
  }
}

// TUICUNIT-2697: YT Discounts
const getEarlyBird = (data: TypeYTdiscount) => {
  if (data.earlyBirdEndDate && data.earlyBird) {
    return [
      {
        alignment: 'justify',
        margin: [0, 15, 0, 0],
        columns: [
          {
            layout: 'noBorders',
            table: {
              headerRows: 1,
              widths: [ '*', 100 ],
              body: [
                [
                  {
                    text: 'Limitiertes Frühbucher-Kontingent',
                    style: 'earlybirdHeading',
                    margin: [10, 1, 5, 0],
                    width: '100%',
                    colSpan: 2,
                  }, {}
                ],
                [
                  {
                    text: `Zeitraum: bis zum ${format(new Date(data.earlyBirdEndDate), 'dd.MM.yyyy')}\nAchtung: Für die 1. und 2. vollzahlende Person\nBereits vom Reisepreis abgezogen.\n`,
                    bold: true,
                    style: 'earlybirdText',
                    margin: [10, 0, 0, 1],
                  },
                  {
                    text: data.earlyBird !== null ? formatPrice(data.earlyBird) : '',
                    fontSize: 18,
                    bold: true,
                    margin: [0, -10, 10, 0],
                    style: 'earlybirdPrice',
                    alignment: 'right',
                  },
                ],
              ]
            }
          }
        ]
      }
    ];
  } else {
    return [];
  }
};


const docDefinition = (
  tripInfo: ibeContentTripInfo,
  routeImage: any,
  contact: contactBox,
  ibeCabine: ibeCabinTypesAndCategories[],
  cabinPriceList: Cruise[],
  nightsTable: IbeNightsTable,
  tarifType: string,
  ytData: TypeYTdiscount,
  choosenDepartureAirport: string,
) => ({
  pageOrientation: 'PORTRAIT' as PageOrientation,
  pageSize: 'A4' as PageSize,
  // margin: [left, top, right, bottom]
  pageMargins: [30, 40, 20, 50] as Margins,
  defaultStyle: {
    font: 'SourceSansPro',
  },
  info: {
    title: `TUI Cruises - ${cabinPriceList[0].journeyIdentifier} ${format(
      new Date(tripInfo.dateFrom),
      'dd.MM.yyyy',
    )}-${format(new Date(tripInfo.dateTo), 'dd.MM.yyyy')} Tarif-Info`,
    author: 'TUI Cruises',
  },
  // TDocumentHeaderFooterFunction
  header: (currentPage: number) => [
    {
      margin: [28, 20, 20, 0],
      alignment: 'justify',
      columns: [
        {
          image: 'myShip',
          width: 149,
        },
      ],
    },
  ],
  content: [
    {
      alignment: 'justify',
      margin: [0, 10, 0, 0],
      columns: [
        {
          width: '*',
          style: 'location',
          margin: [0, 0, 30, 15],
          stack: [
            {
              columns: [
                {
                  text: tripInfo.tripName,
                  width: 'auto',
                  margin: [0, 0, 5, 2]
                },
                {
                  width: 24,
                  height: 24,
                  margin: [0, -2, 0, 0],
                  svg: getShipIconByShipID(cabinPriceList[0].shipCode),
                },
              ]
            },
            {
              text: `Zeitraum: ${format(
                new Date(tripInfo.dateFrom),
                'EEEEEE, dd.MM.yyyy',
              )} - ${format(new Date(tripInfo.dateTo), 'EEEEEE, dd.MM.yyyy')}`,
              fontSize: 10,
            },
          ],
        },
      ],
    },
    {
      width: 400,
      columns: [
        {
          width: '50%',
          stack: [
            {
              width: 270,
              svg: routeImage,
            },
            {
              columns: ytData ? getEarlyBird(ytData) : []
            },
            {
              layout: CabinsTablelayout,
              margin: [0, 10, 0, 0],
              table: {
                headerRows: 3,
                widths: [60, 35, 45, 40, 50],
                body: cabinsTable(cabinPriceList, ibeCabine, ytData, getHeadline(tarifType), getShiffsCode(cabinPriceList), choosenDepartureAirport),
              },
            },
            {
              text: '*Berechnung auf Basis des Grundpreises exkl. Frühbucher-Ermäßigung',
              style: 'info',
              margin: [0, 5, 0, 5],
            },
          ]
        },
        {
          width: '50%',
          margin: [10, 0, 0, 0],
          stack: [
            {
              text: tripInfo.numberOfNights + ' Nächte',
              style: 'nightsTableTitle',
              margin: [0, 0, 0, 5],
            },
            {
              layout: NightsTablelayout,
              border: [true, true, true, true],
              borderColor: '#000000',
              borderWidth: 2,
              table: {
                headerRows: 1,
                widths: [28, 80, 45, 35, 35],
                body: nightsTable.table,
              },
            },
            {
              columns: hasSomeTender(tripInfo)
            },
            {
              columns: [
                {
                  width: 15,
                  height: 15,
                  margin: [0, 10, 0, 0],
                  svg:
                    '<svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M12 3a9.01 9.01 0 010 18 9.01 9.01 0 010-18zm0 2a7 7 0 10.02 14.02A7 7 0 0012 5zm-.02 10a1 1 0 01.7.3c.1.1.18.2.23.32a.92.92 0 010 .77 1.02 1.02 0 01-.53.53.96.96 0 01-.76 0 1.07 1.07 0 01-.32-.21.97.97 0 01-.22-1.08.98.98 0 01.9-.63zM12 7c.24 0 .43.01.57.04a.7.7 0 01.28.09c.05.04.09.08.11.12.03.05.04.12.04.21v6.06c0 .1-.01.16-.03.2a.32.32 0 01-.1.13c-.07.04-.17.07-.3.1a3.12 3.12 0 01-1.12 0 .84.84 0 01-.3-.09.38.38 0 01-.11-.13.48.48 0 01-.04-.21V7.48c0-.1.01-.17.04-.22.02-.05.06-.1.11-.13a.8.8 0 01.3-.1c.13-.02.31-.03.55-.03z" fill="#929EB4" fill-rule="nonzero"/></svg>',
                },
                {
                  width: 240,
                  margin: [7, 10, 0, 10],
                  text:
                    'Es wird der zurzeit günstigste verfügbare Flug berücksichtigt. Der Flugpreis kann je nach Abflughafen und Termin durch Zuschläge variieren. Preise zu allen verfügbaren Abflughäfen erhalten Sie im Bereich Kreuzfahrten finden auf der Preisauskunft Seite zur jeweiligen Reise.',
                  style: 'info',
                },
              ],
            },
            //Travelagency Info
            {
              layout: {
                hLineColor: () => '#333333',
                vLineColor: () => '#333333',
                hLineWidth: (i: number, node: any) => (i === 0 || i === node.table.body.length) ? 0.7 : 0,
                vLineWidth: (i: number, node: any) => (i === 0 || i === node.table.widths.length) ? 0.7 : 0,
              },
              table: {
                headerRows: 0,
                widths: ['*'],
                body: [
                  [
                    {
                      text: 'Mein Reisebüro',
                      style: 'travelagencyHeading',
                    },
                  ],
                  [
                    {
                      text: 'Ihr Ansprechpartner:',
                      style: 'travelagencySubheading',
                      bold: true,
                    },
                  ],
                  [
                    {
                      text: contact.text,
                      style: 'travelagencyText',
                    },
                  ],
                ],
              },
            },
          ]
        }
      ]
    },
  ],
  footer: (currentPage: number, pageCount: number) => [
    {
      margin: [28, 0, 20, 0],
      columns: [
        {
          text: `Stand: ${format(new Date(), 'dd.MM.yyyy, HH:mm')} Uhr`,
          style: 'footerText',
          margin: [0, 10, 0, 0],
        },
        {},
        { image: 'tuic', width: 73 },
      ],
    },
  ],
  styles: {
    earlybirdHeading: {
      color: '#253e6a',
      fontSize: 10,
      bold: true,
    },
    earlybirdText: {
      fontSize: 5,
      color: '#253e6a',
      alignment: 'left',
    },
    earlybirdPrice: {
      fontSize: 18,
      color: '#253e6a',
      alignment: 'left',
    },
    subheader: {
      color: '#253e6a',
      fontSize: 6,
    },
    footerText: {
      color: '#929eb4',
      fontSize: 6,
    },
    nightsTableTitle: {
      color: '#253E6A',
      fontSize: 8,
      alignment: 'left',
      bold: true,
    },
    nightsTableHeadline: {
      color: '#929EB4',
      fontSize: 6,
      alignment: 'left',
      margin: [0, 3, 6, 0],
    },
    nightsTableEntry: {
      fontSize: 5,
      color: '#253E6A',
      alignment: 'left',
    },
    cabinsTableTitle: {
      color: '#253E6A',
      fontSize: 6,
      alignment: 'left',
      margin: [0, 1, 6, 1],
    },
    cabinsTableHeadline: {
      color: '#929EB4',
      fontSize: 6,
      alignment: 'left',
      margin: [0, 1, 6, 1],
    },
    cabinsTableEntry: {
      fontSize: 6,
      color: '#253E6A',
      alignment: 'left',
      margin: [0, 1, 0, 1],
    },
    subTableHead: {
      fontSize: 8,
      color: '#929eb4',
      alignment: 'right',
    },
    entry: {
      color: '#253e6a',
      fontSize: 8,
      alignment: 'right',
      bold: true,
      // margin: [left, top, right, bottom]
      margin: [0, 2, 0, 2],
    },
    smallText: {
      color: '#253e6a',
      fontSize: 6,
      alignment: 'left',
    },
    location: {
      color: '#253e6a',
      fontSize: 16,
      bold: true,
      alignment: 'left',
    },
    empty: {
      color: '#253e6a',
      fontSize: 8,
      alignment: 'center',
      margin: [0, 2, 0, 2],
    },
    info: {
      fontSize: 6,
      color: '#929EB4',
    },
    travelagencyHeading: {
      fontSize: 8,
      color: '#253e6a',
      margin: [0, 5, 0, 0],
    },
    travelagencySubheading: {
      fontSize: 6,
      color: '#253e6a',
      margin: [0, 0, 0, 0],
    },
    travelagencyText: {
      fontSize: 5,
      color: '#253e6a',
      margin: [0, 0, 0, 5],
    }
  },
  images,
});

function getHeadline(tarifType: string) {
  return tarifType[0] === 'PRO'
    ? 'Ihr PRO-Tarif pro Person bei 2er-Belegung'
    : ' Ihr Wohfühlpreis pro Person bei 2er-Belegung';
}

function getShiffsCode(cabinPriceList: Cruise[]): string {
  return cabinPriceList.length > 0
    ? cabinPriceList[0].shipCode
      ? cabinPriceList[0].shipCode
      : '-'
    : '-';
}

export default docDefinition;

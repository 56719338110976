export const polyFilled = !('classList' in SVGElement.prototype);

if (polyFilled) {
  Object.defineProperty(SVGElement.prototype, 'classList', {
    get() {
      return {
        contains: (className: string) => {
          return this.className.baseVal.split(' ').indexOf(className) !== -1;
        },
      };
    },
  });
}

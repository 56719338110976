import styled from 'styled-components/macro';

import { ReactComponent as ArrowRight } from './arrow_right.svg';
export { ReactComponent as Plus } from './plus.svg';
export { ReactComponent as Minus } from './minus.svg';
export { ReactComponent as Calendar } from './calendar.svg';
export { ReactComponent as Ticked } from './ticked.svg';
export { ReactComponent as Cross } from './cross.svg';
export { ReactComponent as Help } from './help.svg';
export { ReactComponent as Download } from './download.svg';
export { ReactComponent as Lupe } from './lupe.svg';
export {
  ReactComponent as CheckboxSmallTicked,
} from './checkbox_small_ticked.svg';
export {
  ReactComponent as CheckboxSmallUnTicked,
} from './checkbox_small_unticked.svg';

export const ArrowUp = styled(ArrowRight)`
  transform: rotate(-90deg);
`;
export const ArrowDown = styled(ArrowRight)`
  transform: rotate(90deg);
`;

export { ArrowRight };

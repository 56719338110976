import * as React from 'react';
import {useLocation} from 'react-router';
import styled from 'styled-components/macro';
import Button from '../Button';
import {colors} from '../../lib';

import {SearchState, serialize} from './SearchState';

type Props = {
  state: SearchState;
  resetState: SearchState;
  onClick?: () => void;
  tabIndex?: number;
};

const GhostButton = styled(Button)`
  background: transparent;
  color: ${colors.darkBlue};
  border: 2px solid ${colors.darkBlue};
  margin-right: 20px;
  -webkit-font-smoothing: antialiased;
  opacity: 1;
  box-sizing: content-box;
  
  &:focus,
  &:hover {
    border-color: ${colors.teal};
    background: transparent;
  }
  
  &[disabled] {
    color: ${colors.disabledGray};
    border-color: ${colors.disabledGray};
  }
`;



const Reset: React.FC<Props> = (
  {
    state,
    resetState,
    onClick,
    tabIndex
  }) => {
  const {search} = useLocation();
  const initialSearch = `?${serialize(resetState)}`;
  const newSearch = `?${serialize(state)}`;
  const disabled =
    initialSearch === newSearch && (search === initialSearch || !search);

  return (
    <GhostButton
      disabled={disabled}
      {...{tabIndex}}
      role="button"
      to={'/'}
      onClick={onClick}
    > Auswahl zurücksetzen </GhostButton>
  );
};

export default Reset;

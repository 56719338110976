import * as React from 'react';
import styled from 'styled-components/macro';

import { colors, Plus, Minus } from '../lib';

import Input from './Input';
import InputLabel from './InputLabel';

type Props = Pick<
  React.InputHTMLAttributes<HTMLInputElement>,
  'min' | 'max'
> & {
  onChange: (value: React.ChangeEvent<HTMLInputElement> | number | '') => void;
  value: number | '';
  defaultValue: number | '';
  label: string;
  tabIndex?: number // TODO why is this not included by React.InputHTMLAttributes
  onIncrement?: () => void
  onDecrement?: () => void
  onEnter?: () => void
};

const InputWithoutSpinButtons = styled.input`
  -moz-appearance: textfield;

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    appearance: none;
    margin: 0;
  }
`;

const IconsWrap = styled.span`
  display: block;
  width: 100%;
  position: relative;
  height: 0;

  svg {
    user-select: none;
    position: absolute;
  }
`;

const svgColor = ({ disabled }: { disabled?: boolean }) =>
  disabled ? colors.gray : colors.darkBlue;
const svgCursor = ({ disabled }: { disabled?: boolean }) =>
  disabled ? 'default' : 'pointer';

const StyledMinus = styled(Minus)`
  color: ${svgColor};
  cursor: ${svgCursor};
  right: 22px;
  top: 10px;
`;

const StyledPlus = styled(Plus)`
  color: ${svgColor};
  cursor: ${svgCursor};
  right: 0;
  top: 11px;
`;

export default function NumberInput({
                                      value,
                                      defaultValue,
                                      label,
                                      onChange,
                                      min,
                                      max,
                                      tabIndex,
                                      onIncrement,
                                      onEnter,
                                      onDecrement,
                                    }: Props) {
  const isEmpty = value === '';
  const resetDefault = React.useCallback(() => {
    if (isEmpty) {
      onChange(defaultValue);
    }
  }, [defaultValue, onChange, isEmpty]);
  const increment = React.useCallback(() => {
    onChange(typeof value === 'number' ? value + 1 : defaultValue);
  }, [value, onChange, defaultValue]);
  const decrement = React.useCallback(() => {
    onChange(typeof value === 'number' ? value - 1 : defaultValue);
  }, [value, onChange, defaultValue]);

  return (
    <InputLabel label={label}>
      <IconsWrap>
        <StyledMinus
          data-testid="minus"
          disabled={value === min}
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
            decrement();
            if (onDecrement) {
              onDecrement();
            }
          }}
          tabIndex={-1}
          focusable="false"
        />
        <StyledPlus
          data-testid="plus"
          disabled={value === max}
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
            increment();
            if (onIncrement) {
              onIncrement();
            }
          }}
          tabIndex={-1}
          focusable="false"
        />
      </IconsWrap>
      <Input
        as={InputWithoutSpinButtons}
        type="number"
        value={value}
        onBlur={resetDefault}
        onChange={(value) => {
          onChange(value);
        }}
        onClick={() => {
          if (onEnter) {
            onEnter();
          }
        }}
        min={min}
        max={max}
        {...{ tabIndex }}
      />
    </InputLabel>
  );
}

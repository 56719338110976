import { format } from '../../../lib';
import { postCruiseList } from '../../../api';
import { BaseData } from '../../../lib';
import convertCruises from '../convertCruises';

const getCruises = async (baseData: BaseData) => {
  const request = {
    paging: { resultsTotal: 999999 },
    composition: 'JourneyIdentifierPriceCategoryCabinType',
    passenger: {
      adults: 2,
      children: 0,
    },
    period: {
      start: {
        date: format(new Date(), 'yyyy-MM-dd'),
        postOffset: 365 * 3,
        preOffset: 0,
      }
    }
  };

  const controller = new AbortController();
  const signal = {
    signal: controller.signal,
  };

  return convertCruises(
    await postCruiseList(request, signal),
    baseData,
  );
};

export default getCruises;

import * as React from 'react';
import InputSkeleton from '../InputSkeleton';
import Button from '../Button';

import { Wrapper, Checkbox } from './ArrivalLikeDeparture';
import { SearchWrap, InputWrap, Clear } from './Search';

const Search: React.FC = React.memo(() => (
  <>
    <h1>Kreuzfahrtsuche</h1>
    <SearchWrap>
      <InputWrap>
        <InputSkeleton label="Erwachsene" />
        <InputSkeleton label="Kinder"/>
      </InputWrap>
      <InputWrap>
        <InputSkeleton label="Frühester Reisebeginn" />
        <InputSkeleton label="Späteste Rückreise"/>
      </InputWrap>
      <InputWrap>
        <InputSkeleton label="Reisedauer"/>
        <InputSkeleton label="Region"/>
      </InputWrap>
      <InputWrap>
        <InputSkeleton label="Schiff"/>
        <InputSkeleton label="Kabinentyp"/>
      </InputWrap>
      <InputWrap>
        <InputSkeleton label="Wunschflughafen für Anreise"/>
        <InputSkeleton label="Wunschflughafen für Rückreise"/>
      </InputWrap>
    </SearchWrap>
    <Wrapper disabled>
      <Checkbox /> Wie Hinflug
    </Wrapper>
    <Clear />
    <Button disabled>Reisen anzeigen</Button>
  </>
));

export default Search;

import * as React from 'react';
import styled from 'styled-components/macro';

import { Option } from '../../types';
import { Td } from '../Table';

import { Cabin } from './Types';

type Props = {
  tariff: Option;
  cabins: Cabin[];
};

const Bottom = styled.span`
  display: block;
  position: absolute;
  bottom: 0;
`;

const Wrap = styled.span`
  display: block;
  height: 100%;
  width: 100%;
  position: relative;
`;

const EarlyBirdHint: React.FC<Props> = React.memo(
  ({ tariff, cabins: allCabins }) => {
    const hasEarlyBird = React.useMemo(
      () =>
        allCabins.some(
          ({ tariffCode: t, earlyBird }) => t === tariff.code && earlyBird,
        ),
      [allCabins, tariff],
    );

    if (!hasEarlyBird) {
      return <Td />;
    }

    return (
      <Td right triangle data-testid="earlybird">
        <Wrap>
          <Bottom>enthält Preise mit Frühbucher-Vorteil</Bottom>
        </Wrap>
      </Td>
    );
  },
);

export default EarlyBirdHint;

import * as React from 'react';
import styled from 'styled-components/macro';

import { colors } from '../../lib';
import ContentWrap, { OuterContentWrap } from '../ContentWrap';

import { ReactComponent as TuicLogo } from './tuic-logo.svg';
import ExportPDFBaseData from '../ExportPDFBaseData';

const Paragraph = styled.p`
  font-size: 14px;
  line-height: 1.29;
  color: ${colors.grayBlue};
  columns: 2;
  column-gap: 68px;
`;

const StyledLogo = styled(TuicLogo)`
  margin: 64px 0 78px;
  float: right;
`;

const Footer: React.FC = () => {
  return (
    <OuterContentWrap>
      <ContentWrap>
        <Paragraph id="footer">
          * Aus Sicherheitsgründen können werdende Mütter, die sich bei Reiseantritt in der 24. Schwangerschaftswoche oder darüber hinaus befinden, nicht an einer Reise teilnehmen. Eine Teilnahme von Babys und Kleinkindern an einer Reise ist erst ab 6 Monaten bei Reiseantritt gestattet. Bei Reisen mit mehr als 3 aufeinanderfolgenden Schiffstagen, ist ein Mindestalter von 12 Monaten vorgeschrieben<br /><br />
          Buchungshinweis: Babys ab einem Alter von 6 bis 23 Monaten (Alter bei Reiseantritt) können direkt über das TUI Cruises Serviceteam hinzugebucht werden. Die Höhe der Flugkosten für Babys bei einer An- und Abreise mit dem Flugzeug haben wir <a className="underline" target="_blank" href="https://www.meinschiff.com/an-und-abreise/einflug" rel="noopener noreferrer">hier</a> für Sie zusammengefasst.<br /><br /> Bitte beachten Sie unsere weiteren <a className="underline" target="_blank" href="https://www.meinschiff.com/an-bord/kreuzfahrt-mit-kindern" rel="noopener noreferrer">Informationen und Reisebedingungen</a> für Schwangere und Babys.
        </Paragraph>
        <ExportPDFBaseData />
        <StyledLogo tabIndex={-1} focusable="false" />
      </ContentWrap>
    </OuterContentWrap>
  );
};

export default Footer;

import * as React from 'react';
import styled from 'styled-components/macro';

import { colors } from '../../lib';

import { Tr } from '../Table';

type LinkProps = {
  open?: boolean;
};
const StyledLink = styled.a`
  display: block;
  position: relative;
  outline: none;
  text-decoration: none;
  background-color: ${({ open }: LinkProps) =>
    open ? colors.lightBlue : 'white'};
  &:focus,
  &:hover {
    background-color: ${({ open }: LinkProps) =>
      open ? colors.slightlyLessLightLightBlue : colors.lightBlue};

    & > span > svg {
      color: ${colors.grayBlue};
    }
  }
`;

type CruiseRowProps = LinkProps & {
  onClick: (ev: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
};

const CruiseRow: React.FC<CruiseRowProps> = ({ children, onClick, open }) => {
  return (
    <StyledLink href="#" data-testid="row" onClick={onClick} open={open}>
      <Tr>{children}</Tr>
    </StyledLink>
  );
};

export default CruiseRow;

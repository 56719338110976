import styled from 'styled-components/macro';

import {
  LABEL_WIDTH,
  LABEL_MAX_INNER_MARGIN,
  LABEL_MIN_INNER_MARGIN,
  LABEL_VERTICAL_PADDING,
} from './InputLabel';
import { MIN_PADDING } from '../lib';

export const MIN_WIDTH = LABEL_WIDTH * 5 + LABEL_MIN_INNER_MARGIN * 4;
const MAX_WIDTH = LABEL_WIDTH * 5 + LABEL_MAX_INNER_MARGIN * 4;

export const ContentConfigWrap = styled.div`
  position: absolute;
  display: flex;
  flex-grow: 1;
  width: calc(100% - ${MIN_PADDING}px);
  max-width: ${MAX_WIDTH + MIN_PADDING * 2}px;
  align-items: center;
  justify-content: space-between;
  padding: 0 ${MIN_PADDING}px ${LABEL_VERTICAL_PADDING}px 0;
  margin: 0 auto;
`;

export const Box = styled.div`
  background: white;
  border-radius: 6px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.15);
  width: calc(100% - ${MIN_PADDING * 2}px);
  padding: 48px 0;
  max-width: 1637px;
  margin: 0 auto;
`;

export const OuterContentWrap = styled.div`
  width: 100%;
  padding: 0 ${MIN_PADDING}px;
`;

export default styled.div`
  position: relative;
  min-width: ${MIN_WIDTH + MIN_PADDING * 2}px;
  max-width: ${MAX_WIDTH + MIN_PADDING * 2}px;
  padding: 0 ${MIN_PADDING}px;
  margin: 0 auto;
`;

import { fetch } from '../lib';

/**
 * Typs for YT Discount detail pdf export
 */
export type TypeYTdiscount = {
  earlyBird: number | null;
  earlyBirdStartDate: string | null;
  earlyBirdEndDate: string | null;
  singleExtra: number | null;
  thirdBedChild: number | null;
  childDiscount: number | null;
  thirdBedAdult: number | null;
  flightPriceOnly: number | null;
};

export type TypeYTDiscountCabin = {
  singleExtra: number | null;
  thirdBedChild: number | null;
  childDiscount: number | null;
  thirdBedAdult: number | null;
};

export type TypeYTDiscountEarlyBird = {
  earlyBird: number | null;
  earlyBirdStartDate: string | null;
  earlyBirdEndDate: string | null;
};


export async function getYtDiscount(tripCode: string, cabinCode: string) {
  const data = await fetch(
    `/yt-api/additional-prices?tripCode=${tripCode}&cabinCode=${cabinCode}`,
  ).then((response) => {
    return response.json();
  })
  .catch(error => {
    return {
      earlyBird: null,
      earlyBirdStartDate: null,
      earlyBirdEndDate: null,
      singleExtra: null,
      thirdBedChild: null,
      childDiscount: null,
      thirdBedAdult: null,
      flightPriceOnly: null
    };
  });

  return data as TypeYTdiscount;
}

import * as React from 'react';
import styled from 'styled-components/macro';

import { LABEL_WIDTH } from '../InputLabel';
import { colors } from '../../lib';

const Link = styled.a`
  position: relative;
  z-index: 0;
  margin-top: 5px;
  font-size: 14px;
  line-height: 1.29;
  display: inline-block;
  text-decoration: none;
  width: ${LABEL_WIDTH}px;
  color: ${colors.grayBlue};
`;

const BabyInfo: React.FC = () => {
  return (
    <Link tabIndex={-1} href="#footer">
      Informationen zu Schwangerschaft und Baby *
    </Link>
  );
};

export default BabyInfo;

import React, { useEffect } from 'react';
import { Route, useRouteMatch } from 'react-router';
import {
  Banner,
  CruiseDetails,
  CruiseList,
  ErrorBoundary,
  Footer,
  Header,
  PriceSwitchProvider,
  Search,
} from './components';
import { BaseDataProvider, CRUISES_ROUTE, GlobalStyle, SearchParameterProvider, UserProvider } from './lib';
import { trackPageView } from './tealium';

export default function App() {

  const matchDetails = useRouteMatch<{ detail: string }>(`${CRUISES_ROUTE}/:detail`);
  const matchCruises = useRouteMatch(CRUISES_ROUTE);

  useEffect(() => {
    if (matchDetails) {
      //gets called directly after the call
    } else if (matchCruises) {
      trackPageView('expertsearch_meinschiff_com|suchergebnisseite');
    } else {
      trackPageView('expertsearch_meinschiff_com|startseite');
    }
  }, [matchCruises, matchDetails]);

  return (
    <>
      <GlobalStyle />
      <Header />
      <ErrorBoundary>
        <UserProvider>
          <BaseDataProvider>
            <PriceSwitchProvider>
              <SearchParameterProvider>
                <Banner>
                  <Search />
                </Banner>
                <Route path={CRUISES_ROUTE} component={CruiseList} />
                <CruiseDetails />
                <Footer />
              </SearchParameterProvider>
            </PriceSwitchProvider>
          </BaseDataProvider>
        </UserProvider>
      </ErrorBoundary>
    </>
  );
}

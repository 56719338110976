import { useEffect, useRef, useState, MutableRefObject } from 'react';

export type Filter = (elm: Element) => boolean;

const clickRef = { click: false };
window.addEventListener('mousedown', () => {
  clickRef.click = true;
});
window.addEventListener('keydown', () => {
  clickRef.click = false;
});

type UseActiveOpts = {
  filter?: Filter;
  mouse?: boolean;
};
export default function useActive<E extends HTMLElement>({
  filter,
  mouse = true,
}: UseActiveOpts = {}): [
  MutableRefObject<E | null>,
  boolean,
  (active: boolean) => void,
] {
  const [active, setActive] = useState<boolean>(false);
  const ref = useRef<E>(null);

  useEffect(() => {
    const callback = (ev: FocusEvent | MouseEvent) => {
      if (!ref.current) {
        return;
      }
      if (!(ev.target instanceof Element)) {
        setActive(false);
        return;
      }
      if (filter && !filter(ev.target)) {
        return;
      }
      const inside = ref.current.contains(ev.target);
      if (!inside) {
        setActive(false);
        return;
      }

      if (!mouse && inside && clickRef.click) {
        return;
      }

      setActive(inside);
    };
    window.addEventListener('focusin', callback);
    window.addEventListener('mousedown', callback);

    return () => {
      window.removeEventListener('focusin', callback);
      window.removeEventListener('mousedown', callback);
    };
  }, [ref, setActive, filter, mouse]);

  return [ref, active, setActive];
}

import styled, { keyframes } from 'styled-components/macro';

const shimmer = keyframes`
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0);
  }
`;

export default styled.span<{ height?: number; width?: number; sep?: boolean }>`
  display: block;
  height: ${(p) => p.height || 17}px;
  width: ${(p) => (p.width ? `${p.width}px` : '100%')};
  border-radius: 8px;
  margin-bottom: ${(p) => (p.sep ? 20 : 10)}px;
  position: relative;
  overflow: hidden;
  :before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: #c8ced9;
    opacity: 0.33;
  }
  :after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 300%;
    animation: ${shimmer} 1.2s linear forwards infinite;
    background: linear-gradient(
      90deg,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0) 30%,
      rgba(255, 255, 255, 0.5) 40%,
      rgba(255, 255, 255, 0) 50%,
      rgba(255, 255, 255, 0) 100%
    );
  }
`;

import * as React from 'react';
import { useEffect, useMemo } from 'react';
import styled from 'styled-components/macro';

import {
  colors,
  Cross,
  DetailParams,
  format,
  Help,
  IBE_URL,
  Lupe,
  MIN_PADDING,
  useAsyncEffect,
  useBaseData,
} from '../../lib';
import { getIbeTripInfo, ibeContentTripInfo } from '../../api/ibeApi/';
import { trackGA4ClickEvent, trackGA4ViewItemEvent } from '../../tealium';
import { Cruise, isCabinWithFlightOnly, Price } from '../CruiseList';
import { formatPrice } from '../PriceSwitch';
import Button from '../Button';

import InfoBlock from './InfoBlock';
import FlightSelect, { useFlightSelectReducer } from './FlightSelect';
import DetailPDFexport from '../DetailPDFexport';


type Props = {
  params: DetailParams;
  cruise: Cruise;
  request: any;
};

export const Wrapper = styled.div`
  position: relative;
  display: flex;
`;

export const InfoMapWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const InfoWrapper = styled.div`
  width: 324px;
  padding-right: ${MIN_PADDING}px;
`;

const RouteMap = styled.div`
  cursor: pointer;
  height: 80px;
  overflow: hidden;
  position: relative;
  width: 120px;
  margin-right: 40px;
  background-color: #85cee4;

  img {
    height: 80px;
  }


  .svgBG {
    position: absolute;
    top: 20px;
    left: 40px;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: rgba(37, 62, 106, 0.4);
    display: flex;
    align-items: center;
    justify-content: center;

    > svg {
      width: 20px;
      height: 20px;
      fill: white;
    }
  }

`;


const RouteMapModal = styled.div`
  position: fixed;
  cursor: pointer;
  top: -100vh;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 200;
`;

const RouteMapModalBG = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgb(37, 62, 106);
  opacity: 0.2;
`;

const RouteMapInnerModal = styled.div`
  position: relative;
  width: 1000px;
  bottom: -60vh;
  margin: 0 auto;
  background: white;
  border-radius: 6px;
  z-index: 500;
  padding: 30px 50px 50px 50px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.15);

  > img {
    display: block;
    height: auto;
    width: 100%;
  }

  // IE11 mediaQuery
  @media all and (-ms-high-contrast: none) {
    bottom: -60%;
  }
`;


export const TitleMap = styled.span`
  font-size: 22px;
  min-height: 28px;
  margin-bottom: 6px;
  padding-bottom: 20px;
  display: block;
  font-weight: bold;
`;

const Close = styled.div`
  z-index: 1;
  position: absolute;
  display: block;
  top: 30px;
  right: ${MIN_PADDING}px;
  color: ${colors.darkBlue};

  &:hover,
  &:focus {
    opacity: 0.5;
  }

  padding: 1px;

  svg {
    height: 14px;
    width: 14px;
  }
`;

export const FlightWrapper = styled.div`
  width: 319px;
  padding-right: 119px;
`;

export const SumWrapper = styled.div`
  padding-left: 120px;
`;

export const ButtonWrapper = styled.div`
  position: absolute;
  bottom: 0;
`;

export const Vr = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 461px;
  height: 100%;
  width: 1px;
  background-color: ${colors.lightGrayBlue};
`;

const PriceList = styled.ol`
  padding-left: 16px;
  font-size: 14px;
  line-height: 1.29;
  margin-bottom: 8px;

  li {
    position: relative;
  }
`;

const Right = styled.span`
  position: absolute;
  right: 0;
`;

const Subtext = styled.div`
  font-size: 14px;
  line-height: 20px;
  margin-top: 20px;
`;

const Gray = styled.span`
  color: ${colors.grayBlue};
`;

const CHILDREN_PRICES = ['K' /* Kinder */, 'B' /* Babys */];
const PRO_TARIFF_CODE_RGX = /^pro$/i;

const IbeBlockedContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 288px;
  margin-top: 47px;
  background-color: ${colors.darkBlue};
  color: white;
  padding: 15px 20px;

  strong {
    font-size: 18px;
    line-height: 20px;
    display: block;
    margin: 0 0 4px 12px;
  }
`;


const IbeBlocked: React.FC = () => {
  return (
    <IbeBlockedContainer>
      <div>
        <Help />
      </div>
      <strong>Reise nur noch über das CRS-System buchbar</strong>
    </IbeBlockedContainer>
  );
};

const CruiseDetails: React.FC<Props> = ({
                                          cruise,
                                          params,
                                          request,
                                        }) => {
  const {
    startDate,
    nights,
    name,
    shipName,
    journeyIdentifier,
    ibeBlocked,
    cabins: [cabin],
  } = cruise;
  const baseData = useBaseData();
  const [state, dispatch] = useFlightSelectReducer(cabin, params);
  const [tripInfo, setTripInfo] = React.useState<null | ibeContentTripInfo>(null);
  const [mapModal, setMapModal] = React.useState<boolean>(false);

  const price =
    useMemo(() => (state.withFlight && state.selectedFlight) ||
      (!isCabinWithFlightOnly(cabin) && cabin.price), [cabin, state.selectedFlight, state.withFlight]);
  const [adults, children] = React.useMemo(
    () =>
      price
        ? price.perPerson
          .sort(({ amount: A }, { amount: B }) => B - A)
          .reduce(
            ([adults, children], price) => {
              if (CHILDREN_PRICES.includes(price.title)) {
                children.push(price);
              } else {
                adults.push(price);
              }
              return [adults, children];
            },
            [[], []] as [Price[], Price[]],
          )
        : [[], []],
    [price],
  );

  const airportQueryParams = useMemo(() => {
    return `${state.withFlight && state.selectedFlight
    && state.selectedFlight.departureAirport.code ? `&shipBoundAirport=${state.selectedFlight.departureAirport.code}` : ''}${state.withFlight && state.selectedFlight
    && state.selectedFlight.arrivalAirport.code ? `&homeBoundAirport=${state.selectedFlight.arrivalAirport.code}` : ''}`;
  }, [state]);

  useAsyncEffect(async () => {
    const data: ibeContentTripInfo = await getIbeTripInfo(journeyIdentifier);
    setTripInfo(data);
  }, [journeyIdentifier]);



  useEffect(() => {
    if (cruise && tripInfo && price) {
      trackGA4ViewItemEvent(cruise, tripInfo, price);
    }
  }, [tripInfo, cruise, price]);

  return (
    <Wrapper>
      {mapModal ? (
        <RouteMapModal onClick={() => {
          setMapModal(false);
        }}>
          <RouteMapModalBG></RouteMapModalBG>
          <RouteMapInnerModal>
            <Close>
              <Cross />
            </Close>
            <TitleMap>{name}</TitleMap>
            {tripInfo && tripInfo.routeMapURL ?
              <img alt="Route" src={tripInfo.routeMapURL} width="auto" height="auto" />
              : null}
          </RouteMapInnerModal>
        </RouteMapModal>
      ) : null}
      <InfoWrapper>
        <InfoMapWrapper>
          <span>
            <InfoBlock label="Abreise" headline={format(startDate)}>
              {nights === '1' ? 'Eine Nacht' : `${nights} Nächte`}
            </InfoBlock>
          </span>
          <RouteMap>
            {tripInfo && tripInfo.routeMapURL !== null ? (
              <div onClick={() => {
                setMapModal(true);
                trackGA4ClickEvent('layer.map');
              }}>
                <img alt="Route" src={tripInfo.routeMapURL} width="auto" height="auto" />
                <div className="svgBG">
                  <Lupe></Lupe>
                </div>
              </div>
            ) : null}
          </RouteMap>
        </InfoMapWrapper>
        <InfoBlock label="Reise" headline={name}>
          {shipName}
        </InfoBlock>
        <InfoBlock label="Kabine" headline={cabin.name}>
          {cabin.tariff}
        </InfoBlock>
        {cabin.tariffCode === 'WFP' || cabin.tariffCode === 'PRO' ? (
          <DetailPDFexport
            label={`Preisübersicht ${cabin.tariff}`}
            tripCode={journeyIdentifier}
            detailRequest={request}
            baseData={baseData}
            cabinCode={cabin.code}
            flightState={state}
          />
        ) : null}
      </InfoWrapper>
      <FlightWrapper>
        <FlightSelect
          basePrice={price || undefined}
          state={state}
          dispatch={dispatch}
        />
      </FlightWrapper>

      <Vr />
      <SumWrapper>
        {price ? (
          <>
            <InfoBlock
              label="Preis"
              headline={
                <>
                  <strong>ab {formatPrice(price.amount)}</strong> Gesamtpreis
                </>
              }
            />
            <PriceList>
              {adults.map(({ amount }, i) => (
                <li key={i}>
                  Erwachsener <Right>{formatPrice(amount)}</Right>
                </li>
              ))}
            </PriceList>
            <PriceList>
              {children.map(({ amount }, i) => (
                <li key={i}>
                  Kind <Right>{formatPrice(amount)}</Right>
                </li>
              ))}
            </PriceList>
            <Subtext>
              {state.withFlight ? (
                state.hasNoInboundFlightOption ||
                state.hasNoOutboundFlightOption ? (
                  <div>inkl. Flug</div>
                ) : (
                  <div>inkl. Hin- und Rückflug</div>
                )
              ) : null}
              {cabin.earlyBird ? <div>inkl. Frühbucher-Vorteil</div> : null}
            </Subtext>
            {PRO_TARIFF_CODE_RGX.test(cabin.tariffCode) ? (
              <Subtext>
                <Gray>(Verfügbarkeit Exklusivitätspakete prüfen)</Gray>
              </Subtext>
            ) : null}
            <ButtonWrapper>
              {ibeBlocked ? (
                <IbeBlocked />
              ) : (
                <Button
                  onClick={() => {
                    trackGA4ClickEvent('layer.link.CTA_Details & Buchung');
                  }}
                  tabIndex={0}
                  target="_blank"
                  href={`${IBE_URL}?tripCode=${journeyIdentifier}&ePackageCode=${
                    cabin.packageReference
                  }&cabinType=${
                    /* Context-switch, des einen codes ist des anderen types */
                    cabin.code.substring(0, cabin.code.length - 2)
                  }&adults=${adults.length}&childs=${children.length}${airportQueryParams}&backLink=${encodeURIComponent(window.location.href)}&ecListPage=1&ecListCode=&ecListName=expertsearch&ecListPosition=${params.listIndex + 1}`}
                >
                  Details &amp; Buchung
                </Button>
              )}
            </ButtonWrapper>
          </>
        ) : null}
      </SumWrapper>
    </Wrapper>
  );
};

export default CruiseDetails;

import {
  Cabin as CabinType,
  Cruise as CruiseType,
  isCabinWithFlight,
  isCabinWithFlightOnly
} from '../Types';
// import { emptyText, TARIFFS } from './constants';
import { emptyText } from './constants';
import { format } from '../../../lib';

const price = (cabin?: CabinType, withFlight?: boolean): string => {
  if (!cabin) {
    return emptyText;
  }
  try {
    if (withFlight) {
      if (isCabinWithFlightOnly(cabin)) {
        return `ab ${cabin.pricesWithFlight[0].perPerson[0].amount},- €`;
      }
      return isCabinWithFlight(cabin) ? `ab ${cabin.pricesWithFlight[0].perPerson[0].amount},- €` : emptyText;
    }
    return !isCabinWithFlightOnly(cabin) ? `${cabin.price.perPerson[0].amount},- €` : emptyText;
  } catch (exc) {
    return emptyText;
  }
};

const tariffTable = (tariffCode: string, cruises: CruiseType[]): any[] => {
  // const isPur = tariffCode === TARIFFS.pur.code;
  const isPur = true; // TUICUNIT-1737

  const tableHead = [
    { text: 'Reisetermine', style: 'mainTableHead', alignment: 'left', colSpan: 4 },
    { text: 'Cruise only', style: 'mainTableHead', colSpan: isPur ? 3 : 4 },
    { text: 'inkl. Flug', style: 'mainTableHead', colSpan: isPur ? 3 : 4 }
  ];

  const mTop = 3;
  const mBot = 10;
  let table: any[] = [
    [],
    [
      { text: 'Abfahrt', style: 'subTableHead', alignment: 'left', margin: [6 , mTop, 0, mBot] },
      { text: 'Nächte', style: 'subTableHead', alignment: 'left', margin: [0 , mTop, 0, mBot]},
      { text: 'Reise', style: 'subTableHead', alignment: 'left', margin: [0 , mTop, 0, mBot] },
      { text: 'Buchungscode', style: 'subTableHead', alignment: 'left', margin: [0 , mTop, 6, mBot] },

      { text: 'Innen', style: 'subTableHead', margin: [0 , mTop, 0, mBot] },
      { text: 'Außen', style: 'subTableHead', margin: [0 , mTop, 0, mBot] },
      { text: 'Balkon', style: 'subTableHead', margin: isPur ? [6 , mTop, 6, mBot] : [0 , mTop, 0, mBot] },
      !isPur ? { text: 'Balkon Plus', style: 'subTableHead', margin: [6 , mTop, 6, mBot] } : null,

      { text: 'Innen', style: 'subTableHead', margin: [0 , mTop, 0, mBot] },
      { text: 'Außen', style: 'subTableHead', margin: [0 , mTop, 0, mBot] },
      { text: 'Balkon', style: 'subTableHead', margin: isPur ? [6 , mTop, 6, mBot] : [0 , mTop, 0, mBot] },
      !isPur ? { text: 'Balkon Plus', style: 'subTableHead', margin: [6 , mTop, 6, mBot] } : null,
    ].filter(t => !!t),
  ];

  // dynamically add empty columns for different tariffs
  tableHead.forEach(th => {
    table[0].push(th);
    for (let i = 0; i < th.colSpan - 1; i++) {
      table[0].push({});
    }
  });

  cruises.forEach((cruise: CruiseType): void => {
    if (cruise.cabins.find(c => c.tariffCode === tariffCode)) {
      const interior = cruise.cabins.find(c => c.type === 'Interior' && c.tariffCode === tariffCode);
      const outside = cruise.cabins.find(c => c.type === 'Outside' && c.tariffCode === tariffCode);
      const balcony = cruise.cabins.find(c => c.type === 'Balcony' && c.tariffCode === tariffCode);
      const balconyPlus = cruise.cabins.find(c => c.type === 'Veranda' && c.tariffCode === tariffCode); // TODO balconyPlus type

      const interiorPrice = price(interior);
      const outsidePrice = price(outside);
      const balconyPrice = price(balcony,);
      const balconyPlusPrice = !isPur ? price(balconyPlus) : null;

      let hasPrice = interiorPrice !== emptyText || outsidePrice !== emptyText || balconyPrice !== emptyText;
      hasPrice = balconyPlusPrice ? (hasPrice || balconyPlusPrice !== emptyText) : hasPrice;

      const interiorPriceFlight = price(interior, true);
      const outsidePriceFlight = price(outside,true);
      const balconyPriceFlight = price(balcony, true);
      const balconyPlusPriceFlight = !isPur ? price(balconyPlus, true) : null;

      let hasPriceFlight = interiorPriceFlight !== emptyText || outsidePriceFlight !== emptyText || balconyPriceFlight !== emptyText;
      hasPriceFlight = balconyPlusPriceFlight ? (hasPriceFlight || balconyPlusPriceFlight !== emptyText) : hasPriceFlight;

      if (!hasPrice && !hasPriceFlight) {
        return;
      }

      let row: any = [
        { text: format(cruise.startDate, 'dd. MMM yyyy'), style: 'entry', alignment: 'left', margin: [6 , 2, 0, 2] },
        { text: cruise.nights, style: 'entry', alignment: 'left', margin: [0 , 2, 0, 3], bold: false },
        { text: cruise.name, style: 'entry', alignment: 'left', margin: [0 , 2, 0, 2] },
        { text: cruise.shipCode, style: 'entrySmall', margin: [0 , 4, 6, 3] },

        { text: interiorPrice, style: 'entry' },
        { text: outsidePrice, style: 'entry' },
        { text: balconyPrice, style: 'entry', margin: isPur ? [0 , 2, 6, 2] : [0 , 2, 0, 2] },
        !isPur ? { text: balconyPlusPrice, style: 'entry', margin: [0 , 2, 6, 2] } : null,
      ].filter(t => !!t);

      if (hasPriceFlight) {
        row.push(
          { text: interiorPriceFlight, style: 'entry' },
          { text: outsidePriceFlight, style: 'entry' },
          { text: balconyPriceFlight, style: 'entry', margin: isPur ? [0 , 2, 6, 2] : [0 , 2, 0, 2] },
          !isPur ? { text: balconyPlusPriceFlight, style: 'entry', margin: [0 , 2, 6, 2] } : null,
        );
      } else {
        row.push(
          { text: 'nicht verfügbar', style: 'empty', colSpan: isPur ? 3 : 4 },
          {},
          {},
          !isPur ? {} : null,
        );
      }

      table.push(row.filter((r: any) => !!r));
    }
  });

  return table;
};

export default tariffTable;

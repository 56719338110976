import React from 'react';
import { useUser } from './auth';
import useBaseData from './useBaseData';
import ErrorWithReset from '../components/ErrorWithReset';

type Options = {
  showError?: boolean;
  Fallback?: React.ComponentType;
};

export default function withUserAndBaseData<P extends object>(
  WrappedComponent: React.ComponentType<P>,
  { showError, Fallback }: Options = {},
): React.ComponentType<P> {
  return (props: P) => {
    const userData = useUser(true);
    const baseData = useBaseData(true);

    if (showError && baseData instanceof Error) {
      return <ErrorWithReset />;
    }

    if (!userData || !baseData || baseData instanceof Error) {
      return Fallback ? <Fallback /> : null;
    }

    return <WrappedComponent {...props} />;
  };
}

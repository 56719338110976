const layout = {
  hLineColor: (i: number) => {
    return i === 1 ? '#c8ced9' : '#eef0f3';
  },
  vLineColor: () => {
    return '#c8ced9';
  },
  hLineWidth: (i: number, node: any) => {
    return i === 0 || i === 2 || i === node.table.body.length ? 0 : 1;
  },
  vLineWidth: (i: number) => {
    return i === 4 || i === 7 ? 1 : 0;
  },
};

const tableLayout = {
  cruiseTable: {
    ...layout,
  },
  cruiseTablePlus: {
    ...layout,
    vLineWidth: (i: number) => {
      return i === 4 || i === 8 ? 1 : 0;
    },
  }
};

export default tableLayout;

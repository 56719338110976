export default async function getSvgFromUrl(url: string) {
  const svg = await fetch('/its/?src=' + url)
    .then((r) => r.text())
    .then((svgAsText) => {
      return svgAsText;
    });
  return simplifySvgHeader(svg);
}

function simplifySvgHeader(svgData: string) {
  const shortSVGHeader =
    '<svg xmlns="http://www.w3.org/2000/svg" width="640" height="360" viewBox="0 0 640 360">';
  let svgHeader = svgData.substring(
    svgData.indexOf('<svg'),
    svgData.indexOf('>', svgData.indexOf('<svg')) + 1,
  );
  return svgData.replace(svgHeader, shortSVGHeader);
}

import * as React from 'react';
import styled from 'styled-components/macro';

import { useActive, colors } from '../lib';

type LabelProps = {
  disabled: boolean;
};
const Label = styled.label<LabelProps>`
  display: inline-block;
  position: relative;
  font-size: 18px;
  height: 24px;
  color: ${({ disabled }) => (disabled ? colors.disabledGray : colors.blue)};
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
  padding: 0 20px 0 25px;
  line-height: 24px;
  &:last-child {
    padding-right: 0;
  }
  &:hover > span {
    border-color: ${({ disabled }) =>
      disabled ? colors.disabledGray : colors.blue};
  }
`;
const Hide = styled.span`
  display: block;
  width: 0;
  height: 0;
  overflow: hidden;
`;

type FakeRadioProps = {
  checked: boolean;
  active: boolean;
  disabled: boolean;
};
const FakeRadio = styled.span<FakeRadioProps>`
  position: absolute;
  display: block;
  top: 2px;
  left: 0;
  border-radius: 10px;
  height: 20px;
  width: 20px;
  border: solid 1px
    ${({ active, disabled }) =>
      disabled ? colors.disabledGray : active ? colors.blue : colors.grayBlue};
  &::before {
    content: '';
    display: ${({ checked }) => (checked ? 'block' : 'none')};
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: ${({ disabled }) =>
      disabled ? colors.disabledGray : colors.blue};
    margin: 4px;
  }
`;

type Props = {
  checked: boolean;
  disabled: boolean;
  onChange: (ev: React.ChangeEvent<HTMLInputElement>) => void;
  innerRef?: React.MutableRefObject<HTMLInputElement | null>;
};

const RadioInput: React.FC<Props> = ({
  children,
  checked,
  onChange,
  disabled,
  innerRef,
}) => {
  const [ref, active] = useActive<HTMLLabelElement>({ mouse: false });

  return (
    <Label ref={ref} disabled={disabled}>
      <FakeRadio active={active} checked={checked} disabled={disabled} />
      <Hide>
        <input
          ref={innerRef}
          type="checkbox"
          checked={checked}
          onChange={onChange}
          disabled={disabled}
        />
      </Hide>
      {children}
    </Label>
  );
};

export default RadioInput;

import * as React from 'react';
import styled from 'styled-components/macro';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import de from 'date-fns/locale/de';

import { colors, DATE_FORMAT, Calendar, ArrowUp } from '../lib';

import { LABEL_WIDTH } from './InputLabel';
import Input from './Input';
import InputLabel from './InputLabel';
import { format } from 'date-fns/esm';
import { isValid } from 'date-fns';
import { isSafari, isIE } from '../lib/userAgent';
import { useEffect } from 'react';

type DatePickerProps = Pick<
  React.ComponentProps<typeof DatePicker>,
  'minDate' | 'maxDate' | 'onBlur'
> & {
  onChange: (value: Date) => void;
  value: Date;
  active: boolean;
  setActive: (active: boolean) => void;
  onEnter?: () => void;
};

type Props = Omit<DatePickerProps, 'active' | 'setActive' | 'onBlur'> & {
  defaultValue: DatePickerProps['value'];
  label: string;
  tabIndex?: number;
};

type IconsProps = Pick<DatePickerProps, 'active' | 'setActive'>;

const DatePickerStyler = styled.div`
  .react-datepicker {
    border: none;
    font-family: SourceSansPro, sans-serif;
    width: ${LABEL_WIDTH}px;
  }

  .react-datepicker-popper {
    position: relative !important;
    transform: none !important;
    margin-top: 0 !important;
  }

  .react-datepicker__current-month {
    color: ${colors.blue};
    font-weight: normal;
    font-size: 18px;
    margin-bottom: 16px;
  }

  .react-datepicker__navigation {
    margin-top: 1px;
  }

  .react-datepicker__navigation--previous {
    border-right-color: ${colors.gray};
    left: 0;

    &:hover,
    &:focus {
      border-right-color: ${colors.blue};
    }
  }

  .react-datepicker__navigation--next {
    border-left-color: ${colors.gray};
    right: 0;

    &:hover,
    &:focus {
      border-left-color: ${colors.blue};
    }
  }

  .react-datepicker-wrapper {
    width: 100%;
  }

  .react-datepicker__month-container {
    float: none;
  }

  .react-datepicker__month {
    margin: 0;
  }

  .react-datepicker__triangle {
    display: none;
  }

  .react-datepicker__day--today {
    font-weight: normal;
  }

  .react-datepicker__day-name,
  .react-datepicker__day {
    color: ${colors.darkBlue};
    margin: 0;
    width: ${LABEL_WIDTH / 7}px;
  }

  .react-datepicker__day:hover,
  .react-datepicker__day--disabled {
    background: transparent;
    color: ${colors.gray};
  }

  .react-datepicker__day--selected {
    background: transparent;
    color: ${colors.blue};
    font-weight: bold;
  }

  .react-datepicker__day--keyboard-selected {
    color: ${colors.blue};
    background: ${colors.lightGrayBlue};
  }

  .react-datepicker__day--keyboard-selected:hover,
  .react-datepicker__day--selected:hover {
    color: ${colors.blue};
    cursor: default;
  }

  .react-datepicker__header {
    margin-top: 24px;
    background: transparent;
    border: none;
  }

  .react-datepicker__day--outside-month {
    opacity: 0;
  }
`;

const IconsWrap = styled.span`
  display: block;
  width: 100%;
  position: relative;
  height: 0;

  svg {
    z-index: 1;
    user-select: none;
    cursor: pointer;
    position: absolute;

    color: ${colors.darkBlue};
    right: 0;
    top: 7px;
  }
`;

const StyledArrowUp = styled(ArrowUp)`
  top: 10px !important;
`;

function ignoreNavigationClicks(el: Element) {
  return (
    Boolean(el.className.split) &&
    !el.className.split(' ').includes('react-datepicker__navigation')
  );
}


/*
1120
1121
31121
311221
01121
011221
10121
010121
0112021
1012021
01012021 // 8
*/

const DateInput: React.FC<DatePickerProps> = ({
                                                value,
                                                active,
                                                onBlur,
                                                onChange,
                                                onEnter,
                                                setActive,
                                                ...datePickerProps
                                              }) => {

  // transforms dates into YYYY-MM-DD
  function transformDateToISODateString(value: Date) {
    return value && value.toLocaleDateString(
      'ja-JP',
      {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
      },
    ).replace(/\//gi, '-').padStart(10, '0');
  }

  // console.log('FORMATS', value, transformDateToISODateString(value), formatISO(value));
  function handleChangeRaw(ev: any) {
    const { value: newValue } = ev.target;
    let newDate;

    if (isSafari() || isIE()) {
      // console.log('handleChangeRaw', newValue, {...ev});
      if (newValue.length >= 8) {
        const simplifiedNewValue = newValue.replace('.', '');
        const day = simplifiedNewValue.substring(0, 2);
        const month = simplifiedNewValue.substring(2, 4);
        const year = simplifiedNewValue.substring(4);
        newDate = new Date(`${year}-${month}-${day}`);
        // console.log('newDate_A', newDate, simplifiedNewValue, day, month, year);
      }
    } else {
      newDate = new Date(newValue);
      // console.log('newDate_B', newDate);
    }

    if (newValue && newDate && isValid(newDate)) {
      setActive(true);
      onChange(newDate);
    }
  }

  const setValueAndClose = React.useCallback((
      value: Date,
      ev: React.SyntheticEvent<HTMLInputElement, InputEvent>,
    ) => {
      const isInsertTextEvent =
        ev.nativeEvent && ev.nativeEvent.inputType === 'insertText';
      const isInputEvent =
        ev.nativeEvent && ev.nativeEvent.type === 'input';

      if (value && !isInsertTextEvent && !isInputEvent) {
        ev.preventDefault();
        ev.stopPropagation();
        setActive(false);
      }
      onChange(value);
    },
    [onChange, setActive],
  );

  useEffect(() => {
    if (active && onEnter) {
      onEnter();
    }
  }, [active, onEnter]);


  // @ts-ignore
  // @ts-ignore
  return (
    <DatePickerStyler>
      <DatePicker
        locale={de}
        required
        customInput={
          (isSafari() || isIE()) ? (
            <Input minLength={8}
                   maxLength={10}
                   type={'text'}
                   pattern="[0-9]{2}\.?[0-9]{2}\.?[0-9]{4}" />
          ) : (
            <Input
              type={'date'} max={'9999-12-31'}
              onClickCapture={(ev) => {
                ev.preventDefault();
                ev.stopPropagation();
              }}
            />
          )
        }
        dateFormat={DATE_FORMAT}
        selected={value}
        isClearable={false}
        open={active}
        onChange={setValueAndClose}
        onBlur={onBlur}
        onChangeRaw={handleChangeRaw}
        renderCustomHeader={({
                               date,
                               decreaseMonth,
                               increaseMonth,
                               prevMonthButtonDisabled,
                               nextMonthButtonDisabled,
                             }) => (
          <>
            <div className="react-datepicker__current-month">
              {format(date, 'MMMM y', { locale: de })}
            </div>
            {prevMonthButtonDisabled ? null : (
              <button
                tabIndex={-1}
                onClick={decreaseMonth}
                className="react-datepicker__navigation react-datepicker__navigation--previous"
              >
                Vorheriger Monat
              </button>
            )}
            {nextMonthButtonDisabled ? null : (
              <button
                tabIndex={-1}
                onClick={increaseMonth}
                className="react-datepicker__navigation react-datepicker__navigation--next"
              >
                Nächster Monat
              </button>
            )}
          </>
        )}
        {...datePickerProps}
        {...(
          !(isSafari() || isIE())
          && { value: (value && transformDateToISODateString(value)) || '' }
        )}
      />
    </DatePickerStyler>
  );
};

const DateIcons: React.FC<IconsProps> = ({ setActive, active }) => {
  const close = React.useCallback(
    (ev: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
      ev.preventDefault();
      ev.stopPropagation();
      setActive(false);
    },
    [setActive],
  );

  return (
    <IconsWrap>
      {active ? (
        <StyledArrowUp data-testid="up-arrow" onClick={close} />
      ) : (
        <Calendar
          tabIndex={-1}
          focusable="false" />
      )}
    </IconsWrap>
  );
};

const DateInputWrap: React.FC<Props> = ({
                                          label,
                                          value,
                                          onChange,
                                          defaultValue,
                                          ...datePickerProps
                                        }) => {
  const isEmpty = value === null;
  const resetDefault = React.useCallback(() => {
    if (isEmpty) {
      onChange(defaultValue);
    }
  }, [defaultValue, onChange, isEmpty]);

  return (
    <InputLabel
      label={label}
      activeFilter={ignoreNavigationClicks}
      render={(active, setActive) => (
        <>
          <DateIcons active={active} setActive={setActive} />
          <DateInput
            active={active}
            setActive={setActive}
            onBlur={resetDefault}
            value={value}
            onChange={onChange}
            {...datePickerProps}
          />
        </>
      )}
    />
  );
};

export default DateInputWrap;

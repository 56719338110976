import { ibeContentItinerary } from '../../../api/ibeApi';
import { format } from 'date-fns';
import { de } from 'date-fns/locale';

export type IbeNightsTable = {
  table: any[];
};
const MAX_COUNTY_NAME_LENGTH = 12;

function nightsTable(itinerary: ibeContentItinerary[]): IbeNightsTable {
  let table: any[] = [];

  const tableHeaderStyle = 'nightsTableHeadline';
  const tableEntryStyle = 'nightsTableEntry';

  table = [
    [
      { text: 'Tag', style: tableHeaderStyle },
      { text: 'Hafen', style: tableHeaderStyle },
      { text: 'Land/Insel', style: tableHeaderStyle },
      { text: 'Ankunft', style: tableHeaderStyle },
      { text: 'Abfahrt', style: tableHeaderStyle },
    ],
  ];

  // TUICUNIT-2570
  const portWithTender = (item:ibeContentItinerary) => {
    if(!!item.tenderPort) {
      return[{
        columns: [{
          bold: true,
          style: tableEntryStyle,
          width: 'auto',
          text: item.portName,
        },{
          width: 8,
          height: 8,
          margin: [ 4, 0, 0, 0 ],
          svg:
            '<svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0" y="0" viewBox="0 0 70.866 70.866" fill="#253e6a" style="enable-background:new 0 0 70.8661423 70.8661423" xml:space="preserve"><path d="M35.434.007C15.855.007-.004 15.877-.004 35.435c0 19.559 15.86 35.428 35.438 35.428 19.569 0 35.428-15.869 35.428-35.428C70.862 15.876 55.003.007 35.434.007zm-.003 66.676c-17.259 0-31.248-13.979-31.248-31.248 0-17.249 13.989-31.248 31.248-31.248 17.25 0 31.248 13.999 31.248 31.248 0 17.27-13.999 31.248-31.248 31.248z"/><path d="M54.286 39.618c-.65-.54-1.59-.57-2.27-.07l-6.97 4.92c-.53.36-.82.98-.8 1.6.03.63.37 1.23.92 1.56l1.18.68c-1.87 2.02-4.8 2.91-8.42 3.22v-21.07h5.67c1.36 0 2.49-1.14 2.49-2.51 0-1.38-1.13-2.51-2.49-2.51h-5.67v-2.6c1.59-.33 2.8-1.69 2.8-3.37V17.7c0-1.92-1.54-3.52-3.469-3.52h-3.63c-1.96 0-3.49 1.6-3.49 3.52v1.77c0 1.68 1.19 3.04 2.79 3.37v2.6h-5.65c-1.37 0-2.5 1.13-2.5 2.51 0 1.37 1.13 2.51 2.5 2.51h5.65v21.068c-3.62-.31-6.55-1.2-8.4-3.22l1.17-.7c.55-.32.9-.91.93-1.54.01-.62-.28-1.23-.79-1.6l-6.989-4.93c-.69-.47-1.61-.44-2.26.08s-.88 1.42-.55 2.18l1.91 4.67c2.43 8.27 10.53 10.32 17.48 10.22h.489c6.86 0 14.63-2.13 17.01-10.23l1.909-4.64c.32-.78.1-1.68-.55-2.2"/></svg>',
        }]
      }];
    } else {
      return {
        bold: true,
        style: tableEntryStyle,
        text: item.portName,
      };
    }
  }

  itinerary.forEach((ele, index) => {
    const margin = (index <= itinerary.length-2) ? [0, 0, 0, 0] : [0, 0, 0, 5];
    table.push([
      {
        text: format(new Date(ele.date), 'dd. LLL', { locale: de }),
        bold: true,
        style: tableEntryStyle,
        margin: margin 
      },
      portWithTender(ele),
      { text: getCountryName(ele.countryName), style: tableEntryStyle },
      { text: getTextOrFiller(ele.arrivalTime), style: tableEntryStyle },
      { text: getTextOrFiller(ele.departureTime), style: tableEntryStyle },
    ]);
  });
  return {table: table};
}

function getCountryName(country: string | null) {
  return country
    ? country.length > MAX_COUNTY_NAME_LENGTH
      ? `${country.slice(0, MAX_COUNTY_NAME_LENGTH)}...`
      : country
    : '-';
}

function getTextOrFiller(data: any) {
  return data ? data : '-';
}

const NightsTablelayout = {
  hLineColor: () => '#333333',
  vLineColor: () => '#333333',
  hLineWidth: (i: number, node: any) => (i === 0 || i === node.table.body.length) ? 0.7 : 0,
  vLineWidth: (i: number, node: any) => (i === 0 || i === node.table.widths.length) ? 0.7 : 0,
};

export { nightsTable, NightsTablelayout };

import * as React from 'react';

import { Link, LinkProps } from 'react-router-dom';
import styled from 'styled-components/macro';

import { colors } from '../lib';

type ButtonBaseProps = {
  disabled?: boolean;
};
const ButtonBase = styled(Link)`
  width: 230px;
  height: 40px;
  border-radius: 24px;
  display: block;
  line-height: 40px;
  text-decoration: none;
  text-align: center;
  font-size: 18px;
  outline: none;
  color: white;
  float: right;
  margin-top: 47px;
  background-color: ${({ disabled }: ButtonBaseProps) =>
    disabled ? '#B3BBC2' : colors.darkBlue};
  opacity: ${({ disabled }: ButtonBaseProps) => (disabled ? 0.6 : 1)};
  cursor: ${({ disabled }: ButtonBaseProps) =>
    disabled ? 'default' : 'pointer'};
  &:focus,
  &:hover {
    background-color: ${({ disabled }: ButtonBaseProps) =>
      disabled ? '#B3BBC2' : colors.teal};
  }
  &:active {
    color: ${({ disabled }: ButtonBaseProps) =>
      disabled ? 'white' : '#bce2eb'};
  }
`;

type ButtonProps = Omit<LinkProps, 'to'> &
  ButtonBaseProps & {
    to?: LinkProps['to'];
  };

const Button: React.FC<ButtonProps> = ({
  disabled,
  to = '#',
  href,
  tabIndex,
  ...linkProps
}) => {
  return (
    <ButtonBase
      as={disabled ? 'span' : href ? 'a' : undefined}
      to={disabled || href ? '#' : to}
      href={disabled ? undefined : href}
      tabIndex={disabled ? undefined : tabIndex}
      disabled={disabled}
      {...linkProps}
    />
  );
};

export default Button;

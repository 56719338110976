import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
    @font-face {
        font-family: 'SourceSansPro';
        font-display: block;
        src: url('/fonts/SourceSansPro-Regular.ttf') format('truetype');
        font-weight: normal;
        font-style: normal;
    }
    
    @font-face {
        font-family: 'SourceSansPro';
        font-display: block;
        src: url('/fonts/SourceSansPro-SemiBold.ttf') format('truetype');
        font-weight: bold;
        font-style: normal;
    }
    
    @font-face {
        font-family: 'SourceSansPro';
        font-display: block;
        src: url('/fonts/SourceSansPro-Regular.ttf') format('truetype');
        font-style: italic;
    }
    
    @font-face {
        font-family: 'SourceSansPro';
        font-display: block;
        src: url('/fonts/SourceSansPro-SemiBold.ttf') format('truetype');
        font-weight: bold;
        font-style: italic;
    }
    
    html {
        box-sizing: border-box;
    }
    
    *,
    *::before,
    *::after {
        box-sizing: border-box;
    }
    
    body {
        margin: 0;
        background: #ffffff;
        color: #253e6a;
    }
    
    body,
    button,
    input {
        font-family: SourceSansPro, sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
    }
    
    button {
        background: none;
        border: none;
        cursor: pointer;
        outline: none;
    }
    
    a {
        color: inherit;
    }
    
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        font-weight: normal;
        line-height: 1.07;
        margin: 0;
    }
    
    h1 {
        font-size: 45px;
        height: 48px;
    }
    
    #root {
        margin-bottom: 74px;
    }
`;

async function getCustomFetch(): Promise<typeof window.fetch> {
  const { fetch }: any = await import('whatwg-fetch');
  return fetch as typeof window.fetch;
}

type ErrorWithResponse = Error & {
  response: Response;
};
function throwNonOkResponse(res: Response) {
  if (!res.ok) {
    const err = new Error(res.statusText) as ErrorWithResponse;
    err.response = res;
    throw err;
  }

  return res;
}

export default async function fetchLoader(
  input: RequestInfo,
  init?: RequestInit,
): Promise<Response> {
  /* Custom fetch in tests required until:
     https://github.com/cypress-io/cypress/issues/687 */
  return (!window.fetch ||
    process.env.NODE_ENV === 'test' ||
    process.env.NODE_ENV === 'development'
    ? await getCustomFetch()
    : window.fetch)(input, init).then(throwNonOkResponse);
}

export function withAbort(onAbort: (abort: () => void) => void) {
  const controller = new AbortController();
  onAbort(controller.abort.bind(controller));
  return {
    signal: controller.signal,
  };
}

export function isAbort(err: any) {
  return (
    err &&
    typeof err.code === 'number' &&
    typeof err.ABORT_ERR === 'number' &&
    err.code === err.ABORT_ERR
  );
}

import * as React from 'react';
import { LinkProps, useHistory, useLocation } from 'react-router-dom';
import styled from 'styled-components/macro';

import { Cross, MIN_PADDING, colors } from '../lib';

import { useHorizontalScroll } from './Fixed';
import ContentWrap, {
  Box,
  MIN_WIDTH as CONTENT_MIN_WIDTH,
} from './ContentWrap';
import FixedSearchLink from './FixedSearchLink';

type ModalProps = {
  active?: boolean;
  tabindex?: number;
};

const ESCAPE_KEY_CODE = 27;

const ModalBoxWrap = styled.div<ModalProps>`
  z-index: 11;
  position: fixed;
  bottom: 0;
  min-width: ${CONTENT_MIN_WIDTH + MIN_PADDING * 4}px;
  width: 100%;
  transform: translateY(${(p) => (p.active ? '0' : '100%')});
  transition: transform 200ms ease-out;
`;

const ModalBoxInner = styled(Box)`
  max-width: 1510px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  padding-top: 64px;
  padding-bottom: 64px;
`;

const ModalBoxContent = styled(ContentWrap)`
  position: relative;
  width: ${1058 - MIN_PADDING * 2}px;
  padding-left: ${27 + MIN_PADDING}px;
`;

const Close = styled(FixedSearchLink)`
  z-index: 1;
  position: absolute;
  display: block;
  top: 0;
  right: ${MIN_PADDING}px;
  color: ${colors.darkBlue};
  &:hover,
  &:focus {
    opacity: 0.5;
  }
  padding: 1px;
  svg {
    height: 14px;
    width: 14px;
  }
`;

export const ModalBox: React.FC<ModalProps & { close: string, onClose?: () => void; }> = ({
  children,
  active,
  close,
  onClose
}) => {
  const ref = useHorizontalScroll<HTMLDivElement>();

  return (
    <ModalBoxWrap ref={ref} active={active}>
      <ModalBoxInner>
        <ModalBoxContent>
          <span onClick={onClose}>
            <Close to={close}>
              <Cross />
            </Close>
          </span>
          <div data-testid="detail-view">{children}</div>
        </ModalBoxContent>
      </ModalBoxInner>
    </ModalBoxWrap>
  );
};

const ModalOverlay = styled(FixedSearchLink)<ModalProps>`
  position: fixed;
  cursor: default;
  z-index: 10;
  top: ${(p) => (p.active ? 0 : '100vh')};
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgb(37, 62, 106);
  opacity: ${(p) => (p.active ? 0.2 : 0)};
  transition: opacity 500ms;
`;

type Focusable = Pick<HTMLElement, 'focus'>;
function isFocusable(val: any): val is Focusable {
  return val && typeof val.focus === 'function';
}

function useFocusReset(active: boolean | undefined) {
  const ref = React.useRef<Focusable | null>(null);
  React.useEffect(() => {
    const { activeElement } = document;
    if (!active) {
      return;
    }
    ref.current = isFocusable(activeElement) ? activeElement : null;
    return () => {
      if (ref.current) {
        ref.current.focus();
      }
    };
  }, [active]);
}
function useEscape(active: boolean | undefined, to: string) {
  const history = useHistory();
  const { search } = useLocation();
  React.useEffect(() => {
    if (!active) {
      return;
    }
    const listener = (ev: KeyboardEvent) => {
      if (ev.keyCode === ESCAPE_KEY_CODE) {
        history.push(`${to}${search}`);
      }
    };
    window.addEventListener('keydown', listener);
    return () => {
      window.removeEventListener('keydown', listener);
    };
  }, [active, search, history, to]);
}

const Modal: React.FC<ModalProps & Omit<LinkProps, 'to'> & { to: string }> = ({
  active,
  to,
  ...rest
}) => {
  useFocusReset(active);
  useEscape(active, to);

  return <ModalOverlay active={active} to={to} {...rest} />;
};

export default Modal;

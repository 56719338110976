import {
  ibeCabinTypesAndCategories,
  ibeCabinCategory,
} from '../../../api/ibeApi';
import { TypeYTdiscount, TypeYTDiscountCabin } from '../../../api/ytDiscount';
import {
  Cruise,
  isCabinWithoutFlight,
  isCabinWithFlight,
  Cabin,
  CabinAndFlightPrice,
} from '../../CruiseList/Types';
import { formatPrice } from '../../PriceSwitch';


const cabinsTable = (
  cabinPriceList: Cruise[],
  ibeCabine: ibeCabinTypesAndCategories[],
  ytData: TypeYTdiscount,
  heading: string,
  shipcode: string,
  choosenDepartureAirport: string,
): any[] => {
  let table: any[] = [];

  const tableTitleStyle = 'cabinsTableTitle'
  const tableHeaderStyle = 'cabinsTableHeadline';
  const tableEntryStyle = 'cabinsTableEntry';

  table = [
    [
      {
        text: heading,
        style: tableTitleStyle,
        margin: [1, 1, 0, 1],
        colSpan: 5,
        border: [true, true, true, true],
      },{},{},{},{},
    ],
    [
      {
        text: `Schiffscode: ${shipcode}`,
        style: tableTitleStyle,
        margin: [1, 1, 0, 1],
        colSpan: 3,
        border: [true, true, true, true],
      },{},{},{
        text: '',
        margin: [1, 1, 0, 1],
        colSpan: 2,
        border: [true, true, true, true],
      },{},
    ],
    [
      {
        text: 'Kabine (Kategorie)',
        style: tableHeaderStyle,
        margin: [1, 1, 0, 1],
        border: [true, true, false, true],
      },
      { text: 'Größe in m2 (Balkon/Veranda)', style: tableHeaderStyle, border: [false, true, false, true]},
      { text: 'Code (max. Belegung)', style: tableHeaderStyle, border: [false, true, true, true] },
      { text: 'ab/bis Hafen', style: tableHeaderStyle, margin: [10, 1, 10, 1], border: [true, true, false, true] },
      {
        text: `inkl. Flug ab ${
          choosenDepartureAirport != null ? choosenDepartureAirport : ''
        }`,
        style: tableHeaderStyle,
        alignment: 'right',
        margin: [0, 1, 10, 1],
        border: [false, true, true, true]
      },
    ],
  ];

  ibeCabine.forEach((cabinCat) => {
    //Array nach Suffix Sortieren!
    let sortetCabinCat = getSuffixSortetCategories(cabinCat.cabinCategories);

    sortetCabinCat.forEach((cabin) => {
      let meta = getCabinInformations(
        cabinCat,
        cabin,
        cabinPriceList,
        choosenDepartureAirport,
      );
      if (meta !== null) {
        table.push([
          {
            text: getCabinsTypWithSuffix(
              meta.cabinTypeName,
              meta.cabinCategoriesSuffix,
            ),
            bold: true,
            style: tableEntryStyle,
            margin: [5, 1, 0, 1],
            border: [true, false, false, false],
          },
          {
            text: getCabinsInAndOutSize(
              meta.interiorSizeInfo,
              meta.exteriorSizeInfo,
            ),
            style: tableEntryStyle,
          },
          {
            text: getCabinCodeWithOccupation(
              meta.cabinCode,
              meta.maxOccupation,
            ),
            style: tableEntryStyle,
            border: [false, false, true, false],
          },
          {
            text:
              meta.price.withoutFlight !== 0
                ? formatPrice(meta.price.withoutFlight)
                : ' - ',
            bold: true,
            style: tableEntryStyle,
            margin: [10, 1, 0, 1],
          },
          {
            text:
              meta.price.withFlight !== 0
                ? formatPrice(meta.price.withFlight)
                : ' - ',
            bold: true,
            style: tableEntryStyle,
            margin: [10, 1, 10, 1],
            border: [false, false, true, false],
          },
        ]);
      }
    });
  });

  if (table.length > 26) {
    table = table.slice(0, 27);
  }

  const discount = ytData ? getDiscounts(ytData, tableEntryStyle) : null;
  if (discount) {
    table = table.concat(discount);
  }
  return table;
};

export type CabinMetaInfo = {
  interiorSizeInfo: any;
  exteriorSizeInfo: any;
  maxOccupation: any;
  cabinCategoriesSuffix: string | null;
  cabinCode: string;
  price: Priceses;
  cabinTypeName: string;
};

type Priceses = {
  withFlight: number;
  withoutFlight: number;
};

function getPrices(cabin: Cabin, airport: string | undefined): Priceses {
  if (isCabinWithFlight(cabin)) {
    return {
      withoutFlight: cabin.price.perPerson[0].amount,
      withFlight: airport
        ? getPriceFromDepartureAirport(cabin.pricesWithFlight, airport)
        : 0,
    };
  } else {
    if (isCabinWithoutFlight(cabin)) {
      return {
        withoutFlight: cabin.price.perPerson[0].amount,
        withFlight: 0,
      };
    } else {
      return {
        withoutFlight: 0,
        withFlight: airport
          ? getPriceFromDepartureAirport(cabin.pricesWithFlight, airport)
          : 0,
      };
    }
  }
}

const CabinsTablelayout = {
  defaultBorder: false,
  hLineColor: () => '#333333',
  vLineColor: () => '#333333',
  hLineWidth: () => 0.7,
  vLineWidht: () => 0.7,
};

function getCabinInformations(
  ibeCabinCat: ibeCabinTypesAndCategories,
  ibeCabin: ibeCabinCategory,
  cabinPriceList: Cruise[],
  choosenDepartureAirport: string | undefined,
): CabinMetaInfo | null {
  const cabin = cabinPriceList[0].cabins.find(
    (ele) => ele.code.slice(0, 4) === ibeCabin.cabinCategoryCode,
  );
  if (cabin) {
    return {
      maxOccupation: ibeCabinCat.maxOccupation,
      exteriorSizeInfo: ibeCabinCat.exteriorSizeInfo,
      interiorSizeInfo: ibeCabinCat.interiorSizeInfo,
      cabinCode: ibeCabin.cabinCategoryCode,
      cabinCategoriesSuffix: ibeCabin.cabinCategoriesSuffix,
      price: getPrices(cabin, choosenDepartureAirport),
      cabinTypeName: ibeCabinCat.cabinTypeName,
    };
  } else {
    return null;
  }
}

function getCabinsTypWithSuffix(type: string, suffix: string | null) {
  return suffix ? `${type} (${suffix})` : type;
}

function getCabinsInAndOutSize(
  interiorSizeInfo: string,
  exteriorSizeInfo: string,
) {
  return exteriorSizeInfo
    ? `${interiorSizeInfo} (${exteriorSizeInfo})`
    : interiorSizeInfo;
}

function getCabinCodeWithOccupation(code: string, occupation: number) {
  return code ? `${code}(${occupation})` : ' - ';
}

function getPriceFromDepartureAirport(
  pricesWithFlight: CabinAndFlightPrice[],
  airport: string | null,
) {
  let chepast = pricesWithFlight.find((ele) => {
    if (ele.departureAirport && ele.departureAirport.code === airport) {
      return ele;
    } else return false;
  });
  return chepast ? chepast.perPerson[0].amount : 0;
}

function getSuffixSortetCategories(unsortet: ibeCabinCategory[]) {
  let sortet = unsortet.sort((a, b) => {
    if (
      a &&
      a.cabinCategoriesSuffix !== null &&
      b &&
      b.cabinCategoriesSuffix !== null
    ) {
      return a.cabinCategoriesSuffix.localeCompare(b.cabinCategoriesSuffix);
    }
    return 0;
  });

  return sortet;
}

// TUICUNIT-2697: YT Discounts
const getDiscounts = (data: TypeYTdiscount, tableEntryStyle: string) => {
  // find relevate infomation
  const { earlyBird, earlyBirdEndDate, earlyBirdStartDate, ...relevantData } = data;

  // no content
  if(Object.keys(relevantData).every((v:string) => relevantData[v as keyof TypeYTDiscountCabin] === null)) {
    return null;
  }

  let flightPrice = 0;
  if (relevantData.flightPriceOnly && relevantData.flightPriceOnly !== 0) {
    flightPrice = relevantData.flightPriceOnly;
  }

  const table = [];

  const singleExtra = data.singleExtra ? `${data.singleExtra.toFixed(2).replace('.00', '')} %` : ' - ';
  table.push([{
      text: "Zuschlag Einzelbelegung (limitiertes Kontigent)*",
      style: tableEntryStyle,
      bold: true,
      margin: [5, 1, 0, 1],
      colSpan: 3,
      border: [true, true, true, false],
    },{},{},{
      text: singleExtra,
      style: tableEntryStyle,
      bold: true,
      margin: [0, 1, 1, 1],
      alignment: 'right',
      border: [false, true, false, false],
    },{
      text: singleExtra && flightPrice !== 0 ? singleExtra : ' - ',
      style: tableEntryStyle,
      bold: true,
      margin: [0, 1, 11, 1],
      alignment: 'right',
      border: [false, true, true, false],
    }]);

  table.push([{
    text: "Preis ab dem 3. Bett in der Kabine",
    style: tableEntryStyle,
    bold: true,
    margin: [5, 1, 0, 1],
    colSpan: 3,
    border: [true, false, true, false],
  },{},{},{
    text: data.thirdBedAdult ? formatPrice(data.thirdBedAdult) : ' - ',
    style: tableEntryStyle,
    bold: true,
    margin: [0, 1, 1, 1],
    alignment: 'right'
  },{
    text: data.thirdBedAdult && flightPrice !== 0 ? formatPrice(data.thirdBedAdult + flightPrice) : ' - ',
    style: tableEntryStyle,
    bold: true,
    margin: [0, 1, 11, 1],
    alignment: 'right',
    border: [false, false, true, false],
  }]);

  const childDiscount = data.childDiscount ? `${data.childDiscount.toFixed(2).replace('.00', '')} %` : ' - ';
  table.push([{
    text: "Kinderermäßigung (2-14 Jahre bei Reiseantritt) für 1./2. Bett",
    style: tableEntryStyle,
    bold: true,
    margin: [5, 1, 0, 1],
    colSpan: 3,
    border: [true, false, true, false],
  },{},{},{
    text: childDiscount,
    style: tableEntryStyle,
    bold: true,
    margin: [0, 1, 1, 1],
    alignment: 'right'
  },{
    text: childDiscount && flightPrice !== 0 ? childDiscount : ' - ',
    style: tableEntryStyle,
    bold: true,
    margin: [0, 1, 11, 1],
    alignment: 'right',
    border: [false, false, true, false],
  }]);

  table.push([{
    text: "Kinderbasispreis (2-14 Jahre bei Reiseantritt) ab dem 3. Bett",
    style: tableEntryStyle,
    bold: true,
    margin: [5, 1, 0, 1],
    colSpan: 3,
    border: [true, false, true, true],
  },{},{},{
    text: data.thirdBedChild ? formatPrice(data.thirdBedChild) : ' - ',
    style: tableEntryStyle,
    bold: true,
    margin: [0, 1, 1, 1],
    alignment: 'right',
    border: [true, false, false, true],
  },{
    text: data.thirdBedChild && flightPrice !== 0 ? formatPrice(data.thirdBedChild + flightPrice) : ' - ',
    style: tableEntryStyle,
    bold: true,
    margin: [0, 1, 11, 1],
    alignment: 'right',
    border: [false, false, true, true],
  }]);

  return table;
}

export { cabinsTable, CabinsTablelayout };

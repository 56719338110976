import styled, { keyframes } from 'styled-components/macro';

// const load1 = keyframes`
//   0% {
//     -webkit-transform: rotate(0deg);
//     transform: rotate(0deg);
//   }
//   100% {
//     -webkit-transform: rotate(360deg);
//     transform: rotate(360deg);
//   }
// `;
const spin = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;
const makeVisible = keyframes`
  to {
    opacity: 1;
  }
`;

const SIZE = 70;
const SIZE_SMALL = 26;
export default styled.div`
  &,
  &::after {
    border-radius: 50%;
    width: ${SIZE}px;
    height: ${SIZE}px;
  }
  font-size: 10px;
  position: fixed;
  z-index: 11;
  top: calc(50% - ${SIZE / 2}px);
  left: calc(50% - ${SIZE / 2}px);
  text-indent: -9999em;
  border-top: 0.9em solid rgba(255, 255, 255, 0.2);
  border-right: 0.9em solid rgba(255, 255, 255, 0.2);
  border-bottom: 0.9em solid rgba(255, 255, 255, 0.2);
  border-left: 0.9em solid #ffffff;
  transform: translateZ(0);
  opacity: 0;
  animation: ${spin} 1.1s infinite linear,
    ${makeVisible} 100ms linear 1s forwards;
`;

export const SpinnerSmallBlue = styled.div`
  &,
  &::after {
    border-radius: 50%;
    width: ${SIZE_SMALL}px;
    height: ${SIZE_SMALL}px;
  }
  font-size: 10px;
  position: fixed;
  z-index: 11;
  top: calc(50% - ${SIZE_SMALL / 2}px);
  left: calc(50% - ${SIZE_SMALL / 2}px);
  text-indent: -9999em;
  border-top: 2px solid rgba(46, 69, 91, 0.1);
  border-right: 2px solid rgba(46, 69, 91, 0.1);
  border-bottom: 2px solid rgba(46, 69, 91, 0.1);
  border-left: 2px solid #253e6a;
  transform: translateZ(0);
  opacity: 0;
  animation: ${spin} 1.1s infinite linear,
    ${makeVisible} 100ms linear 0s forwards;
`;

import * as React from 'react';
import styled from 'styled-components/macro';

import { colors } from '../../lib';

type Props = {
  label: string;
  headline: React.ReactNode;
};

const Base = styled.div`
  font-size: 14px;
  min-height: 16px;
  line-height: 16px;
`;
const Label = styled(Base)`
  color: ${colors.grayBlue};
  margin-bottom: 9px;
`;
const Headline = styled.div`
  font-size: 18px;
  min-height: 20px;
  line-height: 20px;
  margin-bottom: 3px;
`;
const Content = styled(Base)`
  margin-bottom: 20px;
`;

const InfoBlock: React.FC<Props> = ({ label, headline, children }) => {
  return (
    <>
      <Label>{label}</Label>
      <Headline>{headline}</Headline>
      {children ? <Content>{children}</Content> : null}
    </>
  );
};

export default InfoBlock;
